import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import {
  ConvertSystemName,
  IsNotNullOrWhiteSpace,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import { ListServiceRequest } from "../../../RAFComponents/models/Common/ListRequest";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { getEntityByObjectName } from "../../../RAFMaster/helpers/RMutils";
import { ContentType } from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";

export const getCareInterventionSupportListBycarePlanSectionUID = (
  carePlanSectionUID: string,
  relatedEntityUID: string
) => {
  return new Promise<[]>((resolve, reject) => {
    if (isNotNullAndUndefined(relatedEntityUID)) {
      let relatedFilter: RAFCustomFilter = {};
      relatedFilter.Condition = "and";
      relatedFilter.Rules = [];

      if (IsNotNullOrWhiteSpace(carePlanSectionUID)) {
        let filter1: RAFCustomFilter = {};
        let filterVal1: string[] = [];
        filterVal1.push(carePlanSectionUID);
        filter1.Operator = RAFCustomOperator.Equal;
        filter1.Value = filterVal1;
        filter1.Field = "CarePlanSectionUID";
        relatedFilter.Rules.push(filter1);
      }

      let listServiceRequest = new ListServiceRequest();
      listServiceRequest.CustomFilter = relatedFilter;
      listServiceRequest.Skip = 0;
      listServiceRequest.Take = 0;

      listServiceRequest.EntityUID = relatedEntityUID;

      repositoryActions
        .postDataAndGetResponse(
          "DataList/List",
          listServiceRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (isNotNullAndUndefined(response)) {
            if (response.status === 204) {
              resolve(null);
            } else if (
              isNotNullAndUndefined(response.data) &&
              isNotNullAndUndefined(response.data.Entity)
            ) {
              resolve(response.data.Entity);
            } else if (
              response.data &&
              response.data.ResultTable &&
              response.data.ResultTable.length > 0
            ) {
              resolve(response.data.ResultTable);
            } else {
              resolve(null);
            }
          } else {
            resolve(null);
          }
        })
        .catch((error) => error);
    } else {
      resolve(null);
    }
  });
};

export const getCareInterventionSupportListByCareRecipientUID = (
  careRecipientUID: string,
  entityName: string
) => {
  return new Promise<any[]>((resolve, reject) => {
    if (
      isNotNullAndUndefined(entityName) &&
      isNotNullAndUndefined(careRecipientUID)
    ) {
      let relatedFilter: RAFCustomFilter = {};
      relatedFilter.Condition = "and";
      relatedFilter.Rules = [];

      let filter1: RAFCustomFilter = {};
      let filterVal1: string[] = [];
      filterVal1.push(careRecipientUID);
      filter1.Operator = RAFCustomOperator.Equal;
      filter1.Value = filterVal1;
      filter1.Field = "CareRecipientUID";
      relatedFilter.Rules.push(filter1);

      let listServiceRequest = new ListServiceRequest();
      listServiceRequest.CustomFilter = relatedFilter;
      listServiceRequest.Skip = 0;
      listServiceRequest.Take = 0;

      listServiceRequest.EntityName = entityName;

      repositoryActions
        .postDataAndGetResponse(
          "DataList/List",
          listServiceRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (isNotNullAndUndefined(response)) {
            if (response.status === 204) {
              resolve(null);
            } else if (
              isNotNullAndUndefined(response.data) &&
              isNotNullAndUndefined(response.data.Entity)
            ) {
              resolve(response.data.Entity);
            } else if (
              response.data &&
              response.data.ResultTable &&
              response.data.ResultTable.length > 0
            ) {
              resolve(response.data.ResultTable);
            } else {
              resolve(null);
            }
          } else {
            resolve(null);
          }
        })
        .catch((error) => error);
    } else {
      resolve(null);
    }
  });
};

export const getCareInterventionSupportListByAssessmentUID = (
  assessmentUID: string,
  relatedEntityUID: string
) => {
  return new Promise<[]>((resolve, reject) => {
    if (isNotNullAndUndefined(relatedEntityUID)) {
      let relatedFilter: RAFCustomFilter = {};
      relatedFilter.Condition = "and";
      relatedFilter.Rules = [];

      if (IsNotNullOrWhiteSpace(assessmentUID)) {
        let filter1: RAFCustomFilter = {};
        let filterVal1: string[] = [];
        filterVal1.push(assessmentUID);
        filter1.Operator = RAFCustomOperator.Equal;
        filter1.Value = filterVal1;
        filter1.Field = "CareAssessmentUID";
        relatedFilter.Rules.push(filter1);
      }

      let listServiceRequest = new ListServiceRequest();
      listServiceRequest.CustomFilter = relatedFilter;
      listServiceRequest.Skip = 0;
      listServiceRequest.Take = 0;

      listServiceRequest.EntityUID = relatedEntityUID;

      repositoryActions
        .postDataAndGetResponse(
          "DataList/List",
          listServiceRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (isNotNullAndUndefined(response)) {
            if (response.status === 204) {
              resolve(null);
            } else if (
              isNotNullAndUndefined(response.data) &&
              isNotNullAndUndefined(response.data.Entity)
            ) {
              resolve(response.data.Entity);
            } else if (
              response.data &&
              response.data.ResultTable &&
              response.data.ResultTable.length > 0
            ) {
              resolve(response.data.ResultTable);
            } else {
              resolve(null);
            }
          } else {
            resolve(null);
          }
        })
        .catch((error) => error);
    } else {
      resolve(null);
    }
  });
};

export const getAllStandardizedLibraryItemsByType = (type?: string) => {
  return new Promise<[]>(async (resolve) => {
    let titleCustomFilter: RAFCustomFilter = {}; //StandardizedLibrary
    titleCustomFilter.Condition = "and";
    titleCustomFilter.Rules = [];
    let filter: RAFCustomFilter = {};
    let filterVal: string[] = [];
    filterVal.push(type);
    filter.Operator = RAFCustomOperator.Equal;
    filter.Value = filterVal;
    (filter.Field = "Type"), titleCustomFilter.Rules.push(filter);

    let listServiceRequest = new ListServiceRequest();
    listServiceRequest.CustomFilter = titleCustomFilter;
    listServiceRequest.Skip = 0;
    listServiceRequest.Take = 0;

    repositoryActions
      .postDataAndGetResponse(
        "StandardizedLibrary/List",
        listServiceRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (isNotNullAndUndefined(response)) {
          if (response.status === 204) {
            resolve(null);
          } else if (
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.Entities)
          ) {
            resolve(response.data.Entities);
          } else {
            resolve(null);
          }
        } else {
          resolve(null);
        }
      })
      .catch((error) => error);

  });
};

export const getCareInterventionSupportListByGoalUID = (
  goalUID: string,
  entityName: string
) => {
  return new Promise<any[]>((resolve, reject) => {
    if (isNotNullAndUndefined(entityName) && isNotNullAndUndefined(goalUID)) {
      let relatedFilter: RAFCustomFilter = {};
      relatedFilter.Condition = "and";
      relatedFilter.Rules = [];

      let filter1: RAFCustomFilter = {};
      let filterVal1: string[] = [];
      filterVal1.push(goalUID);
      filter1.Operator = RAFCustomOperator.Equal;
      filter1.Value = filterVal1;
      filter1.Field = "GoalUID";
      relatedFilter.Rules.push(filter1);

      let listServiceRequest = new ListServiceRequest();
      listServiceRequest.CustomFilter = relatedFilter;
      listServiceRequest.Skip = 0;
      listServiceRequest.Take = 0;

      listServiceRequest.EntityName = entityName;

      repositoryActions
        .postDataAndGetResponse(
          "DataList/List",
          listServiceRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (isNotNullAndUndefined(response)) {
            if (response.status === 204) {
              resolve(null);
            } else if (
              isNotNullAndUndefined(response.data) &&
              isNotNullAndUndefined(response.data.Entity)
            ) {
              resolve(response.data.Entity);
            } else if (
              response.data &&
              response.data.ResultTable &&
              response.data.ResultTable.length > 0
            ) {
              resolve(response.data.ResultTable);
            } else {
              resolve(null);
            }
          } else {
            resolve(null);
          }
        })
        .catch((error) => error);
    } else {
      resolve(null);
    }
  });
};
