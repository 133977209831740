import * as R from "ramda";
import React, {
  PropsWithChildren,
  Reducer,
  useEffect,
  useReducer,
} from "react";
import RAFEmptyState from "../../../RAFComponents/Navigation/RAFEmptyState";
import { getUnique } from "../../../RAFComponents/helpers/AppHelper";
import { isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import { getAssessmentListByRecipientAndStatus } from "./CareAssessmentHelper";
import CareAssessmentSection from "./CareAssessmentSection";

interface IProps {
  careRecipientUID?: string;
  assessmentCurrentStatus?: string;
  activeCarePlanUID?: string;
  onDeleteChildItem?: () => void;
}

interface IState {
  isLoading?: boolean;
  careAssessments?: any;
  showRelatedSections?: boolean;
}

function CareAssessmentListContent({ ...props }: PropsWithChildren<IProps>) {
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      careAssessments: null,
      showRelatedSections: false,
    }
  );

  useEffect(() => {
    refresh();
  }, [props.careRecipientUID, props.activeCarePlanUID]);

  const refresh = async () => {
    setState({
      isLoading: true,
    });

    const careAssessments = await getAssessmentListByRecipientAndStatus(
      props.activeCarePlanUID,
      props.careRecipientUID,
      CareEsioEntity.CareAssessment.EntityName,
      props.assessmentCurrentStatus
    );
    setState({
      isLoading: false,
      careAssessments,
    });
  };

  const onDeleteChildItem = () => {
    if (props.onDeleteChildItem) {
      props.onDeleteChildItem();
    } else {
      refresh();
    }
  };

  const careAssessmentSectionListContent = () => {
    const { careAssessments, showRelatedSections } = state;
    // isNotNullAndUndefined(careAssessments) &&
    //   careAssessments.sort((x, y) => (x.Category > y.Category ? 1 : -1));
    let careAssessmentCategory = isNotNullAndUndefined(careAssessments)
      ? getUnique(careAssessments, "categoryuid")
      : null;
    const careAssessmentsClone = R.clone(state.careAssessments);
    // careAssessmentsClone &&
    //   careAssessmentsClone.sort((x, y) =>
    //     x.SubCategory > y.SubCategory ? 1 : -1
    //   );
    return state.isLoading === false ? (
      <div>
        {isNotNullAndUndefined(careAssessmentCategory) &&
        careAssessmentCategory.length > 0 ? (
          careAssessmentCategory.map((item) => {
            return (
              <div key={item.UID} className="col-12">
                {showRelatedSections && (
                  <div className="second_header">
                    <span className="cardTitle ecllipseFirstLine word-break-all">
                      {item.category}
                    </span>
                  </div>
                )}
                <div className={showRelatedSections ? "p-3" : "row gy-3 gx-0"}>
                  {careAssessmentsClone &&
                    careAssessmentsClone.map((subCategory) => {
                      if (item.categoryuid === subCategory.categoryuid) {
                        return (
                          <CareAssessmentSection
                            key={subCategory.UID}
                            careAssessmentUID={subCategory.UID}
                            careAssessmentRow={subCategory}
                            careRecipientUID={props.careRecipientUID}
                            onSave={refresh}
                            onDelete={onDeleteChildItem}
                            showRelatedSections={showRelatedSections}
                            IsCollapsed={true}
                          />
                        );
                      }
                    })}
                </div>
              </div>
            );
          })
        ) : (
          <div className="my-3">
            <div className="row gy-3">
              <div className="col-12">
                <RAFEmptyState
                  title={`No active ${CareEsioEntity.Assessment.DisplayName}`}
                  body={`Please click on add button to add ${CareEsioEntity.Assessment.DisplayName}.`}
                  displayStyle="TextOnly"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    ) : (
      <div className="container-fluid px-0">
        <ACLoadingPanel loadingText="Preparing Data..." />
      </div>
    );
  };

  return (
    <div className="row gx-0 gy-3">
      <div className="col-12">
        {state.isLoading === false ? (
          careAssessmentSectionListContent()
        ) : (
          <div className="container-fluid px-0">
            <ACLoadingPanel loadingText="Preparing Data..." />
          </div>
        )}
      </div>
    </div>
  );
}

export default React.memo(CareAssessmentListContent);
