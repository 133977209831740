import React, { PropsWithChildren, Reducer, useContext, useEffect, useReducer } from "react";
import { FormRenderProps } from "react-final-form";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import { EvaluateRAFFormRules, getFormValue, setFormValue } from "../../../RAFComponents/Inputs/RFFUtils";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import { RAFCustomFilter, RAFCustomOperator } from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { showSuccessToast, showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import { RetrieveRecord, getSessionStorage, setSessionStorage } from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import { ConvertSystemName, IsNullOrWhiteSpace, isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";
import { RAFRuleAction } from "../../../RAFComponents/models/Common/FormLibraryRow";
import { RAFViewRow } from "../../../RAFComponents/models/Common/RAFViewRow";
import { getEntityByObjectName } from "../../../RAFMaster/helpers/RMutils";
import classicFormImg from '../../../assets/CareESIO/ClassicForm.png';
import cardFormImg from '../../../assets/CareESIO/cardForm.svg';
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { CareGoalPermissionConstants } from "../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import { RAFButtonConstant, RAFLayout, StorageKey } from "../../../constants/Common/Constants";
import CreateContent from "../../Common/Create/CreateContent";
import RAFEntityProvider, { RAFEntityContext } from "../../Common/Providers/RAFEntityProvider";
import RAFObjectContextProvider, { RAFObjectContext } from "../../Common/Providers/RAFObjectContextProvider";
import CareCategoryAndSubCategory from "../CareCategoryAndSubCategory";


interface IProps {
    isActive: boolean;
    moduleName: string;
    onSave: (entityId?: string, objectName?: string) => void;
    onClose: () => void;
    initialValues?: any;
    isCreateSubGoal?: boolean;
    parentCareGoalUID?: string;
}

interface IState {
    isLoading: boolean;
    initialValues: any;
    showBackBtn: boolean;
    selectedItemIndex: number;
}

function CreateCareGoal({ ...props }: PropsWithChildren<IProps>) {
    let rafForm: FormRenderProps | null;
    const { formLibrary } = useContext(RAFEntityContext);

    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            isLoading: true,
            initialValues: null,
            showBackBtn: false,
            selectedItemIndex: 0,
        }
    );

    useEffect(() => {
        refresh();
    }, []);

    const refresh = async () => {
        if (props.isActive) {
            let initalObject: any = {};
            let selectedItemIndex = isNotNullAndUndefined(initalObject['CategoryUID']) ?
                isNotNullAndUndefined(initalObject['SubCategoryUID']) ? 2 : 1 : 0;

            let showBackBtn = selectedItemIndex === 0 ? false : true;

            if (props.isCreateSubGoal) {
                const careGoalEntity = await getEntityByObjectName({
                    ObjectName: ConvertSystemName(props.moduleName),
                });

                const parentCareGoalObject = await RetrieveRecord(props.parentCareGoalUID, props.moduleName, careGoalEntity.UID);
                if (isNotNullAndUndefined(parentCareGoalObject)) {
                    initalObject.ParentUID = parentCareGoalObject.UID;
                    initalObject.Parent = parentCareGoalObject.Title;
                    initalObject.CareRecipientUID = parentCareGoalObject.CareRecipientUID;
                    initalObject.CareRecipient = parentCareGoalObject.CareRecipient;
                    initalObject.CategoryUID = parentCareGoalObject.CategoryUID;
                    initalObject.Category = parentCareGoalObject.Category;
                    initalObject.SubCategoryUID = parentCareGoalObject.SubCategoryUID;
                    initalObject.SubCategory = parentCareGoalObject.SubCategory;
                    initalObject.Icon = parentCareGoalObject.Icon;
                    initalObject.GoalType = parentCareGoalObject.GoalType;
                    initalObject.StartDate = parentCareGoalObject.StartDate;
                    initalObject.EndDate = parentCareGoalObject.EndDate;
                    initalObject.StartingValue = parentCareGoalObject.StartingValue;
                    initalObject.TargetValue = parentCareGoalObject.TargetValue;
                    initalObject.GoalStatus = parentCareGoalObject.GoalStatus;
                    initalObject.UpdateMethod = parentCareGoalObject.UpdateMethod;
                    initalObject.Measurement = parentCareGoalObject.Measurement;
                    initalObject.CurrentValue = parentCareGoalObject.CurrentValue;

                    selectedItemIndex = 2;
                    showBackBtn = true;
                }
            } else if (isNotNullAndUndefined(props.initialValues)) {
                initalObject = props.initialValues;
            }

            setState({ initialValues: initalObject, selectedItemIndex, showBackBtn, isLoading: false });
        }
    };


    const onRAFFormOnchange = (values) => {
        let ruleActions: RAFRuleAction[] = formLibrary && formLibrary.FormRules;
        //console.log({ ruleActions });
        if (isNotNullAndUndefined(ruleActions))
            EvaluateRAFFormRules(values, ruleActions);
        return;
    };

    const onSubmitObject = (
        values,
        saveObject?: (
            objectData?: any
        ) => Promise<{ objectUID?: string; objectName?: string; }>
    ) => {
        //console.log('onSubmitObject', values);

        //return;
        saveObject(values)
            .then((response) => {
                if (
                    isNotNullAndUndefined(response) &&
                    isNotNullAndUndefined(response.objectUID)
                ) {
                    showSuccessToast("Saved successfully");

                    let allViews: RAFViewRow[] = JSON.parse(
                        getSessionStorage(
                            StorageKey.allViews_modulename + props.moduleName,
                            true
                        )
                    );
                    let recentlyCreatedView: RAFViewRow =
                        allViews && allViews.find((x) => x.ViewName === "Recently_Created"); //recently_created;
                    if (isNotNullAndUndefined(recentlyCreatedView)) {
                        setSessionStorage(
                            StorageKey.recentViewId_modulename + props.moduleName,
                            true,
                            JSON.stringify({
                                viewId: recentlyCreatedView.UID,
                                viewName: recentlyCreatedView.DisplayName,
                            })
                        );
                    }
                    if (props.onSave)
                        props.onSave(response.objectUID, response.objectName);
                } else {
                    showWarningToast("Sorry something went wrong !");
                }
            })
            .catch((error) => error);
    };

    const onSelectGeneralGoal = () => {
        setFormValue(rafForm, "CategoryUID", null);
        setFormValue(rafForm, "Category", null);
        setFormValue(rafForm, "SubCategoryUID", null);
        setFormValue(rafForm, "SubCategory", null);
        setFormValue(rafForm, "Icon", null);
        setState({ selectedItemIndex: 2, showBackBtn: true });
    };

    const onSelectContentBtn = (action: 'Previous' | 'Next') => {
        if (action === 'Previous') {
            const categoryUID = getFormValue(rafForm, 'CategoryUID');
            if (state.selectedItemIndex === 2 && IsNullOrWhiteSpace(categoryUID)) {
                setState({ selectedItemIndex: 0, showBackBtn: false });
            } else {
                let selectedItemIndex = state.selectedItemIndex - 1;
                const showBackBtn = selectedItemIndex !== 0 ? true : false;
                setState({ selectedItemIndex, showBackBtn });
            }
        }
        else if (action === 'Next') {
            let selectedItemIndex = state.selectedItemIndex + 1;
            const showBackBtn = selectedItemIndex !== 0 ? true : false;
            setState({ selectedItemIndex, showBackBtn });
        }
    };

    if (props.isActive) {
        if (state.isLoading === false) {
            let customFilter: RAFCustomFilter = {};
            customFilter.Condition = "and";
            customFilter.Rules = [];
            let filter: RAFCustomFilter = {};
            let filterVal: string[] = [];
            filterVal.push('Goal');
            filter.Operator = RAFCustomOperator.Equal;
            filter.Value = filterVal;
            filter.Field = "Type",

                customFilter.Rules.push(filter);

            return (
                <div
                    className="h-100"
                    key={props.moduleName}
                    id={`raf_dlg_Outer_Div_${props.moduleName}`}
                >
                    <RAFEntityProvider moduleName={props.moduleName}>
                        <RAFObjectContextProvider
                            moduleName={props.moduleName}
                            progressDivId={`#raf_dlg_Outer_Div_${props.moduleName}`}
                        >
                            <RAFObjectContext.Consumer>
                                {({ /*objectId, rafObject,*/ saveObject }) => {
                                    return (
                                        <RAFForm
                                            initialValues={state.initialValues}
                                            formRef={(g) =>
                                                rafForm = g
                                            }
                                            layout={RAFLayout.TwoColumnLayout}
                                            onSubmit={(values) => onSubmitObject(values, saveObject)}
                                            className="h-100"
                                            onChange={onRAFFormOnchange}
                                        //decorators={[accountDecorators]}
                                        //convertBeforeSave={ConvertAccountRowToAccountDto}
                                        >
                                            <div className="e-dlg-content-outer">
                                                <div className={`e-dlg-body-content${state.selectedItemIndex === 1 ? " p-0" : ''}`}>
                                                    {state.selectedItemIndex === 0 && (
                                                        <div className="row gy-3 justify-content-center">
                                                            <div className="col-auto">
                                                                <div className="header-text-lg">
                                                                    Please specify the type of goal you'd like to add?
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <CustomCardWidget
                                                                    onClick={() => onSelectGeneralGoal()}
                                                                    cardClickable
                                                                    cardContentClassName="d-flex align-items-center flex-column justify-content-center"
                                                                >
                                                                    <div className="raf-avatar raf-avatar-xl circle mb-2">
                                                                        <img src={classicFormImg}></img>
                                                                    </div>
                                                                    <div className="header-text">General Goal</div>
                                                                    <div className="secondary-header-text text-center">This could be a broad goal that encompasses various aspects of the individual's well-being.</div>
                                                                </CustomCardWidget>
                                                            </div>
                                                            <div className="col-12">
                                                                <CustomCardWidget
                                                                    cardClickable
                                                                    cardContentClassName="d-flex align-items-center flex-column justify-content-center"
                                                                    onClick={() => onSelectContentBtn('Next')}
                                                                >
                                                                    <div className="raf-avatar raf-avatar-xl circle mb-2">
                                                                        <img src={cardFormImg}></img>
                                                                    </div>
                                                                    <div className="header-text">Domain-Related Goal</div>
                                                                    <div className="secondary-header-text text-center">This refers to a goal specifically tied to a particular domain or category such as mobility, self-care, communication, etc.</div>
                                                                </CustomCardWidget>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {state.selectedItemIndex === 1 && (
                                                        <div className="">
                                                            <CareCategoryAndSubCategory
                                                                categorySelected={() => onSelectContentBtn('Next')}
                                                                entityName={CareEsioEntity.Assessment.EntityName}
                                                                progressDivId={`#raf_dlg_Outer_Div_${props.moduleName}`}
                                                                isActive
                                                            />
                                                        </div>
                                                    )}
                                                    {state.selectedItemIndex === 2 && (
                                                        <>
                                                            <RAFEntityProvider
                                                                moduleName={CareEsioEntity.CareGoal.EntityName}
                                                            >
                                                                <CreateContent
                                                                    moduleName={CareEsioEntity.CareGoal.EntityName}
                                                                    progressDivId={`#raf_dlg_Outer_Div_${props.moduleName}`}
                                                                />
                                                            </RAFEntityProvider>
                                                            {/* <ACLookupAutoComplete
                                                                label="Title"
                                                                field="Title"
                                                                placeholder="Select Title"
                                                                showLabel
                                                                //showCreateButton
                                                                url="DataList/LookUp"
                                                                createform={RAFCreate}
                                                                moduleName={RAFEntityName.StandardizedLibrary}
                                                                SearchCreateOptionMode={"Footer"}
                                                                customFilter={customFilter}
                                                                required
                                                            /> */}
                                                        </>
                                                    )}
                                                </div>
                                                {state.selectedItemIndex !== 0 && (
                                                    <div className="e-dlg-footerContent ">
                                                        <div className="w-100">
                                                            <div className="row gx-2">
                                                                {(state.showBackBtn) && (
                                                                    <div className="col-auto">
                                                                        <div className="col-auto ms-auto">
                                                                            <RAFButtonComponent
                                                                                type="button"
                                                                                action={RAFButtonConstant.Previous}
                                                                                onClick={() => onSelectContentBtn('Previous')}
                                                                                idString="next_Content"
                                                                                className="e-outline"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {state.selectedItemIndex === 2 && (
                                                                    <div className="col-auto ms-auto">
                                                                        <RAFPermissionRender permissionName={CareGoalPermissionConstants.CareGoalAdd}>
                                                                            <RAFButtonComponent
                                                                                type="button"
                                                                                isPrimary
                                                                                action={RAFButtonConstant.Save}
                                                                                onClick={() => rafForm && rafForm.form.submit()}
                                                                                idString="CreateContent"
                                                                                disabled={rafForm && rafForm.submitting}
                                                                            />
                                                                        </RAFPermissionRender>
                                                                    </div>
                                                                )}
                                                                {state.selectedItemIndex === 2 && (
                                                                    <div className="col-auto">
                                                                        <RAFButtonComponent
                                                                            type="button"
                                                                            action={RAFButtonConstant.Cancel}
                                                                            onClick={props.onClose}
                                                                            idString="CreateContent"
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </RAFForm>
                                    );
                                }}
                            </RAFObjectContext.Consumer>
                        </RAFObjectContextProvider>
                    </RAFEntityProvider>
                </div>
            );
        } else {
            return (
                <div className="container-fluid px-0">
                    <ACLoadingPanel loadingText="Preparing Data..." />
                </div>
            );
        }
    }
}

export default React.memo(CreateCareGoal);