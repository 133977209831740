import * as R from "ramda";
import {
    PropsWithChildren,
    Reducer,
    useEffect,
    useReducer,
    useRef
} from "react";
import { FormRenderProps } from "react-final-form";
import { useRecoilValue } from "recoil";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../RAFComponents/Navigation/RAFDeletedRecordState";
import { RAFAttributesContext } from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { RAFCustomFilter, RAFCustomOperator } from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import {
    showSuccessToast,
    showWarningToast
} from "../../../RAFComponents/Utility/RAFToastComponent";
import {
    getSessionStorage,
    setSessionStorage
} from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import {
    IDialogProps,
    IsNullOrWhiteSpace,
    RAFFormComponentProps,
    isNotNullAndUndefined
} from "../../../RAFComponents/helpers/utils";
import { RAFViewRow } from "../../../RAFComponents/models/Common/RAFViewRow";
import { EvaluateScore } from "../../../RAFMaster/helpers/RMutils";
import { CareGoalPermissionConstants } from "../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
    Constants,
    RAFButtonConstant,
    RAFLayout,
    StorageKey
} from "../../../constants/Common/Constants";
import { atomEditingRecordId } from "../../Common/List/IndexHelper";
import RAFEntityProvider, { RAFEntityContext } from "../../Common/Providers/RAFEntityProvider";
import RAFObjectContextProvider, { RAFObjectContext } from "../../Common/Providers/RAFObjectContextProvider";
import EditContent from "../../Common/Update/EditContent";

interface IProps {
    moduleName?: string;
    onSave?: (entityId?: string, objectName?: string) => void;
    onDelete?: () => void;
    objectUID?: string;
    isActive?: boolean;
}

interface IState {
    isActive?: boolean;
}

function UpdateCareGoal({
    objectUID,
    moduleName,
    isActive,
    ...props
}: PropsWithChildren<IProps & RAFFormComponentProps & IDialogProps>) {
    let rafForm: FormRenderProps | null;
    let submitButtonRef = useRef(null);

    let objectGUID = useRecoilValue(atomEditingRecordId(moduleName));
    objectUID = isNotNullAndUndefined(objectGUID) ? objectGUID : objectUID;

    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            isActive: false,
        }
    );

    useEffect(() => {
        setState({
            isActive: true,
        });
    }, [isActive, objectUID]);

    const onSubmitObject = (
        values,
        entity,
        queryAttributes,
        saveObject?: (
            objectData?: any
        ) => Promise<{ objectUID?: string; objectName?: string; }>
    ) => {
        let updatedValues = R.clone(values);
        if (
            isNotNullAndUndefined(entity) &&
            isNotNullAndUndefined(entity.EntitySettings) &&
            isNotNullAndUndefined(entity.EntitySettings.EnableScore) &&
            entity.EntitySettings.EnableScore === true
        ) {
            updatedValues = EvaluateScore(values, queryAttributes);
        }
        saveObject(updatedValues)
            .then((response) => {
                if (
                    isNotNullAndUndefined(response) &&
                    isNotNullAndUndefined(response.objectUID)
                ) {
                    showSuccessToast("Saved successfully");

                    let allViews: RAFViewRow[] = JSON.parse(
                        getSessionStorage(StorageKey.allViews_modulename + moduleName, true)
                    );
                    let recentlyCreatedView: RAFViewRow =
                        allViews && allViews.find((x) => x.ViewName === "Recently_Created"); //recently_created;
                    if (isNotNullAndUndefined(recentlyCreatedView)) {
                        setSessionStorage(
                            StorageKey.recentViewId_modulename + moduleName,
                            true,
                            JSON.stringify({
                                viewId: recentlyCreatedView.UID,
                                viewName: recentlyCreatedView.DisplayName,
                            })
                        );
                    }
                    if (props.onSave)
                        props.onSave(response.objectUID, response.objectName);
                } else {
                    showWarningToast("Sorry something went wrong !");
                }
            })
            .catch((error) => error);
    };

    const enableSubmitButton = (g: FormRenderProps | null) => {
        if (g) {
            if (
                isNotNullAndUndefined(submitButtonRef) &&
                isNotNullAndUndefined(submitButtonRef.current) &&
                isNotNullAndUndefined(submitButtonRef.current.toggleBtnPermission)
            ) {
                submitButtonRef.current.toggleBtnPermission(g.submitting);
            }
        }
    };

    if (
        isNotNullAndUndefined(objectUID) &&
        !IsNullOrWhiteSpace(objectUID) &&
        state.isActive
    ) {
        let customFilter: RAFCustomFilter = {};
        customFilter.Condition = "and";
        customFilter.Rules = [];
        let filter: RAFCustomFilter = {};
        let filterVal: string[] = [];
        filterVal.push('Goal');
        filter.Operator = RAFCustomOperator.Equal;
        filter.Value = filterVal;
        filter.Field = "Type",

            customFilter.Rules.push(filter);

        return (
            <div
                className="h-100"
                key={`${objectUID}_${moduleName}`}
                id={`raf_dlg_Outer_Div_${moduleName}`}
            >
                <RAFEntityProvider moduleName={moduleName}>
                    <RAFAttributesContext.Consumer>
                        {({ queryAttributes }) => {
                            return (
                                <RAFEntityContext.Consumer>
                                    {({ entity }) => {
                                        return (
                                            <RAFObjectContextProvider
                                                moduleName={moduleName}
                                                objectId={objectUID}
                                                progressDivId={`#raf_dlg_Outer_Div_${moduleName}`}
                                            >
                                                <RAFObjectContext.Consumer>
                                                    {({
                              /*objectId, */ rafObject,
                                                        error,
                                                        saveObject,
                                                    }) => {
                                                        if (
                                                            isActive &&
                                                            isNotNullAndUndefined(rafObject) &&
                                                            !IsNullOrWhiteSpace(rafObject.UID)
                                                        ) {
                                                            return (
                                                                <RAFForm
                                                                    initialValues={rafObject}
                                                                    formRef={(g) => {
                                                                        enableSubmitButton(g);
                                                                        return (rafForm = g);
                                                                    }}
                                                                    layout={RAFLayout.TwoColumnLayout}
                                                                    onSubmit={(values) =>
                                                                        onSubmitObject(
                                                                            values,
                                                                            entity,
                                                                            queryAttributes,
                                                                            saveObject
                                                                        )
                                                                    }
                                                                    primaryKey={rafObject.UID}
                                                                    //decorators={[accountDecorators]}
                                                                    //convertBeforeSave={ConvertAccountRowToAccountDto}
                                                                    className="h-100"
                                                                >
                                                                    <div className="e-dlg-content-outer">
                                                                        <div className="e-dlg-body-content">
                                                                            <RAFEntityProvider
                                                                                moduleName={CareEsioEntity.CareGoal.EntityName}
                                                                            >
                                                                                <EditContent
                                                                                    moduleName={CareEsioEntity.CareGoal.EntityName}
                                                                                    progressDivId={`#raf_dlg_Outer_Div_${moduleName}`}
                                                                                />
                                                                            </RAFEntityProvider>
                                                                            {/* <ACLookupAutoComplete
                                                                                label="Title"
                                                                                field="Title"
                                                                                placeholder="Select Title"
                                                                                showLabel
                                                                                //showCreateButton
                                                                                url="DataList/LookUp"
                                                                                createform={RAFCreate}
                                                                                moduleName={RAFEntityName.StandardizedLibrary}
                                                                                SearchCreateOptionMode={"Footer"}
                                                                                customFilter={customFilter}
                                                                                required
                                                                            /> */}
                                                                        </div>
                                                                        <div className="e-dlg-footerContent">
                                                                            <div className="w-100">
                                                                                <div className="row gx-2">
                                                                                    {isNotNullAndUndefined(
                                                                                        props.onDelete
                                                                                    ) ? (
                                                                                        <div className="col-auto">
                                                                                            <RAFPermissionRender permissionName={CareGoalPermissionConstants.CareGoalDelete}>
                                                                                                <RAFButtonComponent
                                                                                                    action={RAFButtonConstant.Delete}
                                                                                                    onClick={props.onDelete}
                                                                                                    idString="EditContent"
                                                                                                    className="e-danger e-outline"
                                                                                                />
                                                                                            </RAFPermissionRender>
                                                                                        </div>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                    <div className="col-auto ms-auto">
                                                                                        <RAFPermissionRender permissionName={CareGoalPermissionConstants.CareGoalUpdate}>
                                                                                            <RAFButtonComponent
                                                                                                ref={submitButtonRef}
                                                                                                isPrimary
                                                                                                action={RAFButtonConstant.Save}
                                                                                                onClick={() =>
                                                                                                    rafForm && rafForm.form.submit()
                                                                                                }
                                                                                                idString="EditContent"
                                                                                                disabled={
                                                                                                    rafForm && rafForm.submitting
                                                                                                }
                                                                                            />
                                                                                        </RAFPermissionRender>
                                                                                    </div>
                                                                                    <div className="col-auto">
                                                                                        <RAFButtonComponent
                                                                                            action={RAFButtonConstant.Cancel}
                                                                                            onClick={props.onClose}
                                                                                            idString="EditContent"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </RAFForm>
                                                            );
                                                        } else {
                                                            if (error === Constants.RAFObject_NoContent) {
                                                                return (
                                                                    <div className="container-fluid px-0">
                                                                        <RAFDeletedRecordState
                                                                            title="This item is either deleted or You do not have sufficient privileges to view this item."
                                                                        />
                                                                    </div>
                                                                );
                                                            } else {
                                                                return (
                                                                    <div
                                                                        className="container-fluid px-0"
                                                                        style={{ background: "transparent" }}
                                                                    >
                                                                        {/* <ACLoadingPanel loadingText="Loading..." /> */}
                                                                    </div>
                                                                );
                                                            }
                                                        }
                                                    }}
                                                </RAFObjectContext.Consumer>
                                            </RAFObjectContextProvider>
                                        );
                                    }}
                                </RAFEntityContext.Consumer>
                            );
                        }}
                    </RAFAttributesContext.Consumer>
                </RAFEntityProvider>
            </div>
        );
    } else {
        return (
            <div
                className="container-fluid px-0"
                style={{ background: "transparent" }}
            ></div>
        );
    }
}
export default UpdateCareGoal;
