import { DialogUtility } from "@syncfusion/ej2-popups";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import DOMPurify from "dompurify";
import React, {
  PropsWithChildren,
  Reducer,
  useContext,
  useReducer,
  useRef,
} from "react";
import { RRule } from "rrule";
import { RolePermissionsContext } from "../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import { PreventFocusOnDialogOpen } from "../../../RAFComponents/Dialog/SFDialogUtils";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDetailFieldCustom from "../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import RAFDetailsValueWithSeparator from "../../../RAFComponents/Navigation/RAFDetailsValueWithSeparator/RAFDetailsValueWithSeparator";
import RAFRecordInfo from "../../../RAFComponents/Navigation/RAFRecordInfo";
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  DeleteRecord,
  RafClearDialogContent,
  hideProgress,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender, {
  hasPermission,
} from "../../../RAFComponents/helpers/PermissionHelper";
import { RRDays, RRFrequencyTypes } from "../../../RAFComponents/helpers/RRuleInputHelper";
import {
  IsNotNullOrWhiteSpace,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import {
  RAFDataType,
  RAFUIType,
} from "../../../RAFComponents/models/Common/RAFDataType";
import { EntityRow } from "../../../RAFMaster/RMModules/Entity/EntityRow";
import { CareInterventionSupportPermissionConstants } from "../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  BrowserIsDevice,
  MomentFormats,
  RAFButtonConstant,
} from "../../../constants/Common/Constants";
import UpdateCareInterventionSupport from "./UpdateCareInterventionSupport";

interface IProps {
  careInterventionSupportRow: any;
  onSave?: () => void;
  onSelect?: () => void;
  careInterventionSupportEntity?: EntityRow;
  parent?: "CreateSupportTask" | "CareInterventionSupportList";
  uiStyle?: "CardStyle" | "CollapsePanelStyle";
  showIsRequiredBadge?: boolean;

  isChecked?: boolean;
  isDisabled?: boolean;
  showCheckBox?: boolean;
  onChangeCheckBoxValue?: (isChecked: boolean) => void;
  allowEdit?: boolean;
  careAssessmentUID?: string;
  isRelatedSection?: boolean;
}

interface IState {
  showUpdateCareInterventionSupport: boolean;
}

function CareInterventionSupportContent({
  parent = "CareInterventionSupportList",
  allowEdit = true,
  careInterventionSupportRow,
  ...props
}: PropsWithChildren<IProps>) {
  let deleteDialog: any;
  const careInterventionSupportModule =
    CareEsioEntity.CareInterventionSupport.EntityName;
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      showUpdateCareInterventionSupport: false,
    }
  );

  let updateTaskDialogRef = useRef<DialogComponent>(null);
  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;
  const hasPermissionToEdit = hasPermission(
    permissionValue,
    `${careInterventionSupportModule}::Update`
  );

  //UpdateCareInterventionSupport start
  const showUpdateCareInterventionSupportDialog = () => {
    if (hasPermissionToEdit && allowEdit) {
      setState({ showUpdateCareInterventionSupport: true });
    }
  };

  const updateUpdateCareInterventionSupportContent = () => {
    if (state.showUpdateCareInterventionSupport) {
      return (
        <UpdateCareInterventionSupport
          careInterventionSupportRow={careInterventionSupportRow}
          objectUID={careInterventionSupportRow.UID}
          moduleName={careInterventionSupportModule}
          onSave={refreshOnUpdate.bind(this)}
          onClose={closeUpdateCareInterventionSupportDialog.bind(this)}
          onDelete={onClickDeleteSection}
          isActive
        />
      );
    } else {
      return <div></div>;
    }
  };

  const refreshOnUpdate = async () => {
    await RafClearDialogContent(updateTaskDialogRef);
    setState({ showUpdateCareInterventionSupport: false });
    if (props.onSave) {
      props.onSave();
    }
  };

  const closeUpdateCareInterventionSupportDialog = async () => {
    await RafClearDialogContent(updateTaskDialogRef);
    setState({ showUpdateCareInterventionSupport: false });
  };
  //UpdateCareInterventionSupport end

  //delete item start
  const onClickDeleteSection = () => {
    deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Are you sure want to delete?",
      okButton: { text: "Yes", click: deleteRecord.bind(this) },
      //title: `Delete ${CareEsioEntity.CareInterventionSupport.DisplayName}`,
      title: `Delete Task`,
      position: { X: "center", Y: "center" },
      cssClass: `alert-dialog deleteDialog`,
    });
  };

  async function deleteRecord() {
    let progressDiv = showProgress(".deleteDialog.e-dialog");
    let isDeleted = await DeleteRecord(
      careInterventionSupportRow.UID,
      careInterventionSupportModule,
      props.careInterventionSupportEntity.UID
    );

    if (isDeleted) {
      hideProgress(progressDiv);
      deleteDialog.hide();
      refreshOnUpdate();
    } else {
      hideProgress(progressDiv);
      deleteDialog.hide();
      showWarningToast("Sorry something went wrong !");
    }
  }
  //delete item end

  const onSelect = () => {
    if (isNotNullAndUndefined(props.onSelect)) {
      props.onSelect();
    }
  };

  if (isNotNullAndUndefined(careInterventionSupportRow)) {
    const rruleExpression =
      isNotNullAndUndefined(careInterventionSupportRow) &&
        isNotNullAndUndefined(careInterventionSupportRow.RruleExpression)
        ? careInterventionSupportRow.RruleExpression
        : null;
    const ruleString = isNotNullAndUndefined(rruleExpression)
      ? RRule.fromString(rruleExpression)
      : null;
    const ruleOptions = isNotNullAndUndefined(ruleString)
      ? ruleString.origOptions
      : null;

    const ruleFrequency = isNotNullAndUndefined(ruleOptions)
      ? ruleOptions.freq
      : null;
    const ruleByWeekday = isNotNullAndUndefined(ruleOptions)
      ? ruleOptions.byweekday
      : null;

    const frequencyText = isNotNullAndUndefined(ruleFrequency)
      ? RRFrequencyTypes.find((item) => item.value === ruleFrequency)?.text
      : null;

    const frequencyByWeekdayText = isNotNullAndUndefined(ruleByWeekday)
      ? Array.isArray(ruleByWeekday)
        ? RRDays.filter((item) => ruleByWeekday.includes(item.value))
          .map((item) => item.text)
          .join(", ")
        : RRDays.find((item) => item.value === ruleByWeekday)?.text
      : null;
    const frequencyByWeekdayText1 = isNotNullAndUndefined(ruleByWeekday)
      ? Array.isArray(ruleByWeekday)
        ? RRDays.filter((item) => ruleByWeekday.includes(item.value))
        : RRDays.find((item) => item.value === ruleByWeekday)?.text
      : null;
    const session = careInterventionSupportRow.Session;

    const customButton = (
      <>
        <RAFPermissionRender
          permissionName={
            CareInterventionSupportPermissionConstants.CareInterventionSupportUpdate
          }
        >
          <RAFButtonComponent
            action={RAFButtonConstant.Edit}
            className="primary-custom-button custom-button-sm d-none d-md-block"
            onClick={showUpdateCareInterventionSupportDialog}
          ></RAFButtonComponent>
          <RAFButtonComponent
            action={RAFButtonConstant.Edit}
            className="primary-custom-button custom-button-sm d-md-none"
            onClick={showUpdateCareInterventionSupportDialog}
            iconBtn
          ></RAFButtonComponent>
        </RAFPermissionRender>
      </>
    );

    const onChangeCheckBoxValue = (isChecked: boolean) => {
      if (props.onChangeCheckBoxValue) {
        props.onChangeCheckBoxValue(isChecked);
      }
    };

    const IndependenceLevel = (
      colClassName?,
      labelDivClassName?,
      valueDivClassName?
    ) => {
      return (
        <RAFDetailFieldCustom
          value={
            isNotNullAndUndefined(careInterventionSupportRow.IndependenceLevel)
              ? careInterventionSupportRow.IndependenceLevel
              : "Not set"
          }
          title="Independence Level"
          moduleName={CareEsioEntity.CareInterventionSupport.EntityName}
          field="IndependenceLevel"
          uiType={RAFUIType.SmileyToggle}
          showLabel={true}
          rowClassName="g-0 gy-1 flex-column"
          {...(isNotNullAndUndefined(colClassName) && {
            colClassName: colClassName,
          })}
          {...(isNotNullAndUndefined(labelDivClassName) && {
            labelDivClassName: labelDivClassName,
          })}
          {...(isNotNullAndUndefined(valueDivClassName) && {
            valueDivClassName: valueDivClassName,
          })}
        />
      );
    };

    const Frequency = (
      colClassName?,
      labelDivClassName?,
      valueDivClassName?
    ) => {
      return (
        <RAFDetailFieldCustom
          value={
            isNotNullAndUndefined(careInterventionSupportRow.Frequency)
              ? careInterventionSupportRow.Frequency
              : "Not set"
          }
          title="Frequency"
          moduleName={CareEsioEntity.CareInterventionSupport.EntityName}
          field="Frequency"
          showLabel={true}
          rowClassName="g-0 gy-1 flex-column"
          {...(isNotNullAndUndefined(colClassName) && {
            colClassName: colClassName,
          })}
          {...(isNotNullAndUndefined(labelDivClassName) && {
            labelDivClassName: labelDivClassName,
          })}
          {...(isNotNullAndUndefined(valueDivClassName) && {
            valueDivClassName: valueDivClassName,
          })}
        />
      );
    };

    const Goal = (colClassName?, labelDivClassName?, valueDivClassName?) => {
      return (
        <RAFDetailFieldCustom
          value={
            isNotNullAndUndefined(careInterventionSupportRow.Goal)
              ? careInterventionSupportRow.Goal
              : "Not set"
          }
          title="Goal"
          moduleName={CareEsioEntity.CareInterventionSupport.EntityName}
          field="Goal"
          showLabel={true}
          rowClassName="g-0 gy-1 flex-column"
          {...(isNotNullAndUndefined(colClassName) && {
            colClassName: colClassName,
          })}
          {...(isNotNullAndUndefined(labelDivClassName) && {
            labelDivClassName: labelDivClassName,
          })}
          {...(isNotNullAndUndefined(valueDivClassName) && {
            valueDivClassName: valueDivClassName,
          })}
        />
      );
    };

    const customTitle = (
      <>
        {props.showCheckBox ? (
          <div className="col-12">
            <div className="row gx-3">
              <div className="col">
                {IsNotNullOrWhiteSpace(
                  careInterventionSupportRow.SupportType
                ) && (
                    <div className="d-flex align-items-center">
                      <RAFDetailFieldCustom
                        value={
                          isNotNullAndUndefined(
                            careInterventionSupportRow.SupportType
                          )
                            ? careInterventionSupportRow.SupportType
                            : "Not set"
                        }
                        title="SupportType"
                        moduleName={
                          CareEsioEntity.CareInterventionSupport.EntityName
                        }
                        isColorOption
                        field="SupportType"
                        showLabel={false}
                        mode="outlineView"
                        valueClassName="raf_badge raf_sm"
                      />
                    </div>
                  )}
              </div>
              {props.showCheckBox && (
                <div
                  className="col-auto"
                  key={props.isChecked ? props.isChecked.toString() : ""}
                >
                  <CheckBoxComponent
                    name={careInterventionSupportRow.UID}
                    value={careInterventionSupportRow.UID}
                    // cssClass="e-checkbox-light e-checkbox-lg raf-round show-check-icon"
                    cssClass="e-checkbox-light"
                    change={(e) => onChangeCheckBoxValue(e.checked)}
                    checked={props.isChecked ?? false}
                    disabled={props.isDisabled}
                  />
                </div>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="col d-flex align-items-center">
          {IsNotNullOrWhiteSpace(careInterventionSupportRow.SupportType) &&
            !props.showCheckBox ? (
            <>
              <div className="d-flex align-items-center">
                <RAFDetailFieldCustom
                  value={
                    isNotNullAndUndefined(
                      careInterventionSupportRow.SupportType
                    )
                      ? careInterventionSupportRow.SupportType
                      : "Not set"
                  }
                  title="SupportType"
                  moduleName={CareEsioEntity.CareInterventionSupport.EntityName}
                  isColorOption
                  field="SupportType"
                  showLabel={false}
                  mode="outlineView"
                  valueClassName="raf_badge raf_sm"
                />
                {/* <span className="raf_badge raf_badge_tertiary">
            {careInterventionSupportRow.SupportType}
          </span> */}
              </div>
              <div className="group_btn_separator"></div>
            </>
          ) : (
            ""
          )}
          <div>
            <span
              className={props.isRelatedSection ? "subtitle_2" : "subtitle_1"}
            >
              {careInterventionSupportRow.Title}
            </span>
            {isNotNullAndUndefined(props.showIsRequiredBadge) &&
              isNotNullAndUndefined(careInterventionSupportRow.IsRequired) &&
              careInterventionSupportRow.IsRequired === true && (
                <span className="e-badge e-badge-danger ms-2">Must-do</span>
              )}
          </div>
        </div>
        {IsNotNullOrWhiteSpace(careInterventionSupportRow.StartDate) &&
          IsNotNullOrWhiteSpace(careInterventionSupportRow.EndDate) && (
            <div className="col-12 mt-2">
              <RAFDetailsValueWithSeparator
                outerClassName="h-auto"
                rowClassName="flex-nowrap g-2"
              >
                {IsNotNullOrWhiteSpace(
                  careInterventionSupportRow.StartDate
                ) && (
                    <RAFDetailFieldCustom
                      title="Start Date:"
                      value={careInterventionSupportRow.StartDate}
                      labelDivClassName="col-auto"
                      valueDivClassName="col-auto"
                      labelClassName="body_2_light medium"
                      valueClassName="subtitle_2"
                      rowClassName="gx-2"
                      fieldFormat={{
                        type: RAFDataType.Date,
                        format: MomentFormats.DATE,
                      }}
                    ></RAFDetailFieldCustom>
                  )}
                {IsNotNullOrWhiteSpace(careInterventionSupportRow.EndDate) && (
                  <RAFDetailFieldCustom
                    title="End Date:"
                    value={careInterventionSupportRow.EndDate}
                    labelDivClassName="col-auto"
                    valueDivClassName="col-auto"
                    labelClassName="body_2_light medium"
                    valueClassName="subtitle_2"
                    rowClassName="gx-2"
                    fieldFormat={{
                      type: RAFDataType.Date,
                      format: MomentFormats.DATE,
                    }}
                  ></RAFDetailFieldCustom>
                )}
              </RAFDetailsValueWithSeparator>
            </div>
          )}
        {isNotNullAndUndefined(careInterventionSupportRow.Note) &&
          IsNotNullOrWhiteSpace(careInterventionSupportRow.Note) && (
            <div className="col-12 mt-2">
              <CustomCardWidget
                // cardClassName={`surface_neutral_${IsNotNullOrWhiteSpace(props.careAssessmentUID)
                //   ? "light"
                //   : "base"
                //   }`}
                cardClassName={`${props.isRelatedSection ? "surface_neutral_base" : ""
                  }`}
                cardContentClassName={`p-2`}
              >
                <span
                  className={"description-text details-value"}
                  dangerouslySetInnerHTML={{
                    __html:
                      careInterventionSupportRow.Note !== null
                        ? DOMPurify.sanitize(careInterventionSupportRow.Note)
                        : "Not set",
                  }}
                ></span>
              </CustomCardWidget>
            </div>
          )}
        <div className="col-12 mt-3 d-none d-xl-block">
          <div className="">
            <RAFDetailsValueWithSeparator
              outerClassName="line-seprator h-auto"
              rowClassName="g-3"
            >
              {IndependenceLevel(null, "col-auto", "col-auto")}
              {Frequency(null, "col-auto", "col-auto")}
              {Goal(null, "col-auto", "col-auto")}
            </RAFDetailsValueWithSeparator>
          </div>
          <div className="d-xl-none">
            <div className="row g-2 g-md-3">
              {IndependenceLevel("col-12 col-md-6")}
              {Frequency("col-12 col-md-6")}
              {Goal("col-12 col-md-6")}
            </div>
          </div>
        </div>
      </>
    );

    let customFooter = (
      <div
        {...(hasPermissionToEdit && {
          onClick: showUpdateCareInterventionSupportDialog,
        })}
      >
        <div className="custom__card__separator custom__card__separator__light"></div>
        <div className="pe-3 py-2">
          <div className="row gx-2 align-items-center">
            <div className="col-auto">
              <RAFRecordInfo
                createdBy={careInterventionSupportRow.CreatedBy}
                createdDate={careInterventionSupportRow.CreatedDate}
                modifiedDate={careInterventionSupportRow.ModifiedDate}
                modifiedBy={careInterventionSupportRow.ModifiedBy}
                lastActivityDate={careInterventionSupportRow.LastActivityDate}
                spanContent="Updated "
                dropdownBtnClassName="custom-button-sm"
                spanContentClassName="body_3_light medium"
                hideClockIcon
              />
            </div>
            {hasPermissionToEdit && allowEdit && (
              <div className="col-auto ms-auto">
                <RAFButtonComponent
                  action={RAFButtonConstant.Edit}
                  iconBtn
                  className="btn_state_warning white e-round"
                ></RAFButtonComponent>
              </div>
            )}
          </div>
        </div>
      </div>
    );

    const customCard = (
      <div
        className={`p-3${hasPermissionToEdit && allowEdit ? " pointer" : ""}`}
        {...(hasPermissionToEdit && {
          onClick: showUpdateCareInterventionSupportDialog,
        })}
      >
        <div className="row g-3">{customTitle}</div>
      </div>
    );

    const customTitleNew = (
      <div
        className={`custom__card p-3${hasPermissionToEdit && allowEdit ? " pointer" : ""
          }`}
        {...(hasPermissionToEdit && {
          onClick: showUpdateCareInterventionSupportDialog,
        })}
      >
        <div className="row gx-0 gy-1">
          <div className="col d-flex align-items-center justify-content-between">
            <div>
              <span className="header-text">
                {careInterventionSupportRow.Title}
              </span>
            </div>
            {/* <div>{customButton}</div> */}
          </div>
          {isNotNullAndUndefined(careInterventionSupportRow.Note) && (
            <div className="col-12">
              <div className="text_secondary_value">
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      careInterventionSupportRow !== null
                        ? DOMPurify.sanitize(careInterventionSupportRow.Note)
                        : undefined,
                  }}
                ></span>
              </div>
            </div>
          )}
          <div className="col-12 mt-2">
            <div className="detailsLeftCard-second-title h-26">
              <div className="w-100">
                <div className="row gx-2 gy-1 align-items-center">
                  {isNotNullAndUndefined(
                    careInterventionSupportRow.Frequency
                  ) && (
                      <div className="col-auto detailsLeftCard-second-title-col">
                        <div className="detailsLeftCard-second-title-item">
                          <RAFDetailFieldCustom
                            value={
                              isNotNullAndUndefined(
                                careInterventionSupportRow.Frequency
                              )
                                ? careInterventionSupportRow.Frequency
                                : "Not set"
                            }
                            //   displayValue={careInterventionSupportRow.Frequency}
                            title="Frequency"
                            moduleName={
                              CareEsioEntity.CareInterventionSupport.EntityName
                            }
                            isColorOption
                            field="Frequency"
                            showLabel={false}
                            mode="outlineView"
                          />
                          {/* {careInterventionSupportRow.Frequency} */}
                        </div>
                      </div>
                    )}

                  {/* {isNotNullAndUndefined(frequencyText) && (
                      <div className="col-auto detailsLeftCard-second-title-col">
                          <div className="detailsLeftCard-second-title-item">
                              {frequencyText}
                          </div>
                      </div>
                  )} */}
                  {isNotNullAndUndefined(frequencyByWeekdayText1) && (
                    <div className="col-auto detailsLeftCard-second-title-col">
                      <div className="detailsLeftCard-second-title-item">
                        {Array.isArray(frequencyByWeekdayText1) &&
                          frequencyByWeekdayText1?.map(
                            (item: any, index: any) => {
                              return (
                                <span
                                  className={`${index === 0 ? "" : "ms-1 "
                                    }e-badge e-badge-border`}
                                  style={{ minWidth: "25px" }}
                                  key={item.text}
                                >
                                  {item.text.charAt(0)}
                                </span>
                              );
                            }
                          )}
                      </div>
                    </div>
                  )}
                  {isNotNullAndUndefined(session) && (
                    <div className="col-auto detailsLeftCard-second-title-col">
                      <div className="detailsLeftCard-second-title-item">
                        <span className="e-badge e-badge-grey-border">
                          {session}
                        </span>
                      </div>
                    </div>
                  )}
                  {isNotNullAndUndefined(
                    careInterventionSupportRow.IndependenceLevel
                  ) && (
                      <div className="col-auto detailsLeftCard-second-title-col">
                        <div className="detailsLeftCard-second-title-item remove__icon__color">
                          {/* <span className="d-none d-md-inline-block pe-1">
                          Support Level:{" "}
                        </span> */}
                          <RAFDetailFieldCustom
                            value={
                              isNotNullAndUndefined(
                                careInterventionSupportRow.IndependenceLevel
                              )
                                ? careInterventionSupportRow.IndependenceLevel
                                : "Not set"
                            }
                            field="IndependenceLevel"
                            moduleName={
                              CareEsioEntity.CareInterventionSupport.EntityName
                            }
                            // isColorOption={true}
                            showLabel={false}
                            uiType={RAFUIType.SmileyToggle}
                          // mode="outlineView"
                          ></RAFDetailFieldCustom>
                          {/* <RAFDetailFieldCustom
                          value={
                            isNotNullAndUndefined(
                              careInterventionSupportRow.IndependenceLevel
                            )
                              ? careInterventionSupportRow.IndependenceLevel
                              : "Not set"
                          }
                          //   displayValue={careInterventionSupportRow.Frequency}
                          title="Independence Level"
                          moduleName={
                            CareEsioEntity.CareInterventionSupport.EntityName
                          }
                          field="IndependenceLevel"
                          uiType={RAFUIType.SmileyToggle}
                          showLabel={false}
                          mode="opacityColorView"
                        /> */}
                          {/* {careInterventionSupportRow.Frequency} */}
                        </div>
                      </div>
                    )}
                  {isNotNullAndUndefined(careInterventionSupportRow.Goal) && (
                    <div className="col-auto detailsLeftCard-second-title-col">
                      <div className="detailsLeftCard-second-title-item">
                        <i className="fas fa-sm fa-trophy"></i>
                        <span className="ms-2 ecllipseFirstLine">
                          {`Goal-Related`}
                        </span>
                      </div>
                    </div>
                  )}
                  {!BrowserIsDevice && (
                    <div className="col-auto detailsLeftCard-second-title-col d-none d-md-block">
                      <div className="detailsLeftCard-second-title-item">
                        <RAFRecordInfo
                          createdBy={careInterventionSupportRow.CreatedBy}
                          createdDate={careInterventionSupportRow.CreatedDate}
                          modifiedDate={careInterventionSupportRow.ModifiedDate}
                          modifiedBy={careInterventionSupportRow.ModifiedBy}
                          lastActivityDate={
                            careInterventionSupportRow.LastActivityDate
                          }
                          hideClockIcon
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    return (
      <div
        className={
          isNotNullAndUndefined(props.showIsRequiredBadge) &&
            props.showIsRequiredBadge
            ? "col-12"
            : "col-12"
        }
        key={careInterventionSupportRow.UID}
      >
        {props.uiStyle === "CardStyle" ? (
          <div
            {...(parent === "CreateSupportTask" && {
              onClick: () => onSelect(),
            })}
          >
            {customTitleNew}
            {customFooter}
            {/* <CustomCardWidget
              cardClassName={`${careInterventionSupportRow.CareInterventionSupportLevel}`}
              headerTemplate={customTitle}
              {...(parent === "CareInterventionSupportList" && {
                customButton: customButton,
              })}
              {...(parent === "CreateSupportTask" && {
                onClick: () => onSelect(),
              })}
              removeContentPadding
              showCardSeparator={false}
            >
              {isNotNullAndUndefined(careInterventionSupportRow.Note) &&
                IsNotNullOrWhiteSpace(careInterventionSupportRow.Note) && (
                  <span className="secondary-header-text">
                    {careInterventionSupportRow.Note}
                  </span>
                )}
            </CustomCardWidget> */}
          </div>
        ) : (
          <CustomCardWidget
            // cardClassName={`${IsNotNullOrWhiteSpace(props.careAssessmentUID) ? "care_recipient_care_plan_item" : ""}`}
            // onClick={showUpdateCareInterventionSupportDialog}
            {...(parent === "CreateSupportTask" && {
              onClick: () => onSelect(),
            })}
            removeContentPadding
            {...(!props.isRelatedSection
              ? { footerTemplate: customFooter }
              : {})}
            cardClassName={`${!props.isRelatedSection ? "surface_neutral_base " : ""
              }overflow-hidden`}
          // hideCardContent={isNotNullAndUndefined(careInterventionSupportRow.Note) && IsNotNullOrWhiteSpace(careInterventionSupportRow.Note) ? false : true}
          >
            {customCard}
            {/* <RAFCollapseWithPlusIcon
              customTitle={customTitle}
              isCollapsed={true}
              collapsible={
                isNotNullAndUndefined(careInterventionSupportRow.Note) &&
                IsNotNullOrWhiteSpace(careInterventionSupportRow.Note)
                  ? true
                  : false
              }
              collapsePanelContentClassname={"py-2"}
              expandedStateIcon="fas fa-chevron-up small-icon"
              collapsedStateIcon="fas fa-chevron-down small-icon"
              {...(parent === "CareInterventionSupportList" && {
                customButton: customButton,
              })}
            >
              {isNotNullAndUndefined(careInterventionSupportRow.Note) &&
                IsNotNullOrWhiteSpace(careInterventionSupportRow.Note) && (
                  <span className="secondary-header-text">
                    {careInterventionSupportRow.Note}
                  </span>
                )}
            </RAFCollapseWithPlusIcon> */}
          </CustomCardWidget>
        )}
        {state.showUpdateCareInterventionSupport && (
          <DialogComponent
            header={`Update Task`}
            // header={
            //     "Update " +
            //     getDisplayNameByModuleName(careInterventionSupportModule, props.careInterventionSupportEntity.DisplayName)
            // }
            showCloseIcon
            visible={state.showUpdateCareInterventionSupport}
            cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
            id={`dlg_update_${careInterventionSupportModule}`}
            content={updateUpdateCareInterventionSupportContent.bind(this)}
            isModal
            target="body"
            closeOnEscape={false}
            close={closeUpdateCareInterventionSupportDialog.bind(this)}
            zIndex={1200}
            ref={updateTaskDialogRef}
            open={PreventFocusOnDialogOpen}
          ></DialogComponent>
        )}
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default React.memo(CareInterventionSupportContent);
