import { DialogComponent } from "@syncfusion/ej2-react-popups";
import React, {
  forwardRef,
  PropsWithChildren,
  Reducer,
  useEffect,
  useImperativeHandle,
  useReducer,
  useRef,
} from "react";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import { CareInterventionSupportPermissionConstants } from "../../../constants/CareESIO/CareESIOPermissionConstant";
import { RAFButtonConstant } from "../../../constants/Common/Constants";
import { PreventFocusOnDialogOpen } from "../../../RAFComponents/Dialog/SFDialogUtils";
import {
  getAllAttributes,
  getFields,
  getRelatedRecords,
  RafClearDialogContent,
} from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import {
  RAFEventName,
  subscribeRAFEvent,
  unsubscribeRAFEvent,
} from "../../../RAFComponents/helpers/RAFDocumentEvents";
import {
  ConvertSystemName,
  isNotEmptyArray,
  isNotNullAndUndefined,
  IsNotNullOrWhiteSpace,
} from "../../../RAFComponents/helpers/utils";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFCollapseWithPlusIcon from "../../../RAFComponents/Navigation/RAFCollapseWithPlusIcon";
import RAFEmptyState from "../../../RAFComponents/Navigation/RAFEmptyState";
import { getEntityByObjectName } from "../../../RAFMaster/helpers/RMutils";
import { EntityRow } from "../../../RAFMaster/RMModules/Entity/EntityRow";
import { RAFObjectContext } from "../../Common/Providers/RAFObjectContextProvider";
import { getDefaultCareAssessmentFieldValue } from "../CareAssessmentLibrary/CareAssessmentHelper";
import CareInterventionSupportContent from "./CareInterventionSupportContent";
import CreateCareInterventionSupport from "./CreateCareInterventionSupport";

interface IProps {
  careRecipientUID: string;
  careAssessmentUID: string;
  showEmptyState?: boolean;
  showAddBtn?: boolean;
  careAssessmentRow: any;
  uiStyle?:
  | "CardStyle"
  | "DetailsStyle"
  | "ListStyle"
  | "CollapsePanelStyle"
  | "CollapsePanelWithOutCardStyle"
  | "SummaryStyle"
  | "SummaryDetails";
  customCardClass?: string;
  includeHeaderClass?: boolean;
  showCheckBox?: boolean;
  allowEdit?: boolean;
  paddingClass?: string;
}

interface IState {
  isLoading: boolean;
  careInterventionSupportItems: any;
  careInterventionSupportEntity: EntityRow;
  showCreateCareInterventionSupportContent: boolean;
  defaultFieldValue: string;
}

const CareInterventionSupportList = forwardRef(
  function CareInterventionSupportList(
    {
      showAddBtn = true,
      showEmptyState = true,
      uiStyle = "CardStyle",
      includeHeaderClass = true,
      allowEdit = true,
      paddingClass = "p-2-5 p-md-3",
      ...props
    }: PropsWithChildren<IProps>,
    ref
  ) {
    const careInterventionSupportModule =
      CareEsioEntity.CareInterventionSupport.EntityName;

    let addTaskDialogRef = useRef<DialogComponent>(null);

    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
      (state, newState) => ({ ...state, ...newState }),
      {
        isLoading: true,
        careInterventionSupportItems: null,
        careInterventionSupportEntity: null,
        showCreateCareInterventionSupportContent: false,
        defaultFieldValue: null,
      }
    );

    useEffect(() => {
      refresh();
    }, [props.careAssessmentUID]);

    useImperativeHandle(ref, () => ({
      toggleCreateDlg() {
        setState({
          showCreateCareInterventionSupportContent:
            !state.showCreateCareInterventionSupportContent,
        });
      },
    }));

    useEffect(() => {
      const relatedModule = isNotNullAndUndefined(props.careAssessmentUID)
        ? `${CareEsioEntity.CarePlan.EntityName}_${careInterventionSupportModule}`
        : careInterventionSupportModule;
      subscribeRAFEvent(
        `${RAFEventName.GridLoad}_${relatedModule}_${props.careRecipientUID}`,
        reLoadListContent
      );

      return () => {
        unsubscribeRAFEvent(
          `${RAFEventName.GridLoad}_${relatedModule}_${props.careRecipientUID}`,
          reLoadListContent
        );
      };
    });

    const reLoadListContent = () => {
      refresh();
    };

    const refresh = async () => {
      await RafClearDialogContent(addTaskDialogRef);
      if (
        isNotNullAndUndefined(props.careAssessmentUID) ||
        isNotNullAndUndefined(props.careRecipientUID)
      ) {
        setState({
          isLoading: true,
          careInterventionSupportItems: null,
          showCreateCareInterventionSupportContent: false,
        });

        const [careInterventionSupportEntity, attributeRelatedList, queryAttributes] = await Promise.all([
          getEntityByObjectName({
            ObjectName: ConvertSystemName(careInterventionSupportModule),
          }),
          getAllAttributes(ConvertSystemName(careInterventionSupportModule)),
          getFields(ConvertSystemName(careInterventionSupportModule)),
        ]);//dont remove this line  details filed is used in loop below this line add to avoid multiple calls

        const careInterventionSupportItems = isNotNullAndUndefined(
          props.careAssessmentUID
        )
          ? await getRelatedRecords(
            careInterventionSupportModule,
            null,
            "CareAssessmentUID",
            props.careAssessmentUID,
            careInterventionSupportEntity.UID
          )
          : await getRelatedRecords(
            careInterventionSupportModule,
            null,
            "CareRecipientUID",
            props.careRecipientUID,
            careInterventionSupportEntity.UID
          );

        const defaultFieldValue = await getDefaultCareAssessmentFieldValue(
          props.careAssessmentRow
        );

        setState({
          isLoading: false,
          careInterventionSupportItems,
          careInterventionSupportEntity,
          defaultFieldValue,
        });
      }
    };

    const supportNeedsItemSummary = () => {
      if (isNotEmptyArray(state.careInterventionSupportItems)) {
        return (
          <div className="row g-2">
            {state.careInterventionSupportItems.map((item) => {
              return (
                <div className={"col-auto"} key={item.UID}>
                  <div className="raf_badge surface_neutral_base">
                    <span className="body_2_dark ecllipseSecondLine">
                      {item.Title}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        );
      }
    };

    const headerContent = () => {
      return (
        <div className="d-flex align-items-center justify-content-between">
          <div className="header_5">
            {/* {CareEsioEntity.CareInterventionSupport.CollectionName} */}
            Tasks
          </div>
          <div>
            {state.isLoading === false && showAddBtn === true && (
              <div className="d-flex justify-content-center">
                <RAFPermissionRender
                  permissionName={
                    CareInterventionSupportPermissionConstants.CareInterventionSupportAdd
                  }
                >
                  <RAFButtonComponent
                    action={RAFButtonConstant.Add}
                    onClick={() => onClickAddCareInterventionSupport()}
                    className="btn_brand_primary semi_dark"
                  />
                </RAFPermissionRender>
                {createCareInterventionSupportDlgDiv()}
              </div>
            )}
          </div>
        </div>
      );
    };

    const getCareInterventionSupportListContent = () => {
      const supportNeedCount = isNotEmptyArray(
        state.careInterventionSupportItems
      )
        ? state.careInterventionSupportItems.length
        : 0;
      if (uiStyle === "DetailsStyle") {
        return (
          <div className="row g-0 gy-3">
            <div className="col-12">{headerContent()}</div>
            {careInterventionSupportListItem()}
          </div>
        );
      } else if (uiStyle === "ListStyle") {
        return (
          <div className="">
            <ul className="list-group">
              <li className="list-group-item header-text-sm">
                {/* {CareEsioEntity.CareInterventionSupport.CollectionName} */}
                Tasks
              </li>
              {careInterventionSupportListItem()}
            </ul>
            {(state.isLoading === false && showAddBtn === true) && (
              <div className="col-12">
                <div className="py-2 d-flex align-items-center justify-content-end">
                  <RAFPermissionRender
                    permissionName={
                      CareInterventionSupportPermissionConstants.CareInterventionSupportAdd
                    }
                  >
                    <RAFButtonComponent
                      className="custom-button-sm primary-custom-button"
                      // className="custom-button-md"
                      action={RAFButtonConstant.Add}
                      btnContent={`Add Task`}
                      onClick={() => onClickAddCareInterventionSupport()}
                    />
                  </RAFPermissionRender>
                </div>
                {createCareInterventionSupportDlgDiv()}
              </div>
            )}
          </div>
        );
      } else if (
        uiStyle === "CollapsePanelStyle" ||
        uiStyle === "CollapsePanelWithOutCardStyle"
      ) {
        return (
          <div className="col-12">
            <div
              className={`${isNotNullAndUndefined(props.customCardClass)
                ? `${props.customCardClass} `
                : ""
                }${uiStyle === "CollapsePanelWithOutCardStyle"
                  ? ""
                  : "custom__card"
                }`}
            >
              <div
                className={
                  uiStyle === "CollapsePanelWithOutCardStyle"
                    ? ""
                    : "custom__card__content p-0"
                }
              >
                <RAFCollapseWithPlusIcon
                  toggleArrowIcon="Arrow"
                  //title={CareEsioEntity.CareInterventionSupport.CollectionName}
                  title={"Tasks"}
                  allowFullRowClick
                  isCollapsed={true}
                  showSeparator={
                    uiStyle === "CollapsePanelWithOutCardStyle" ? false : true
                  }
                  collapsePanelHeaderClass={`${includeHeaderClass
                    ? ""
                    : "collapsePanel__header__fixedHeight__lg"
                    }${uiStyle === "CollapsePanelWithOutCardStyle"
                      ? includeHeaderClass
                        ? " p-0"
                        : "p-0"
                      : ""
                    }`}
                  collapsePanelContentClassname={`${uiStyle === "CollapsePanelWithOutCardStyle"
                    ? "p-0 pt-3 "
                    : "p-2 p-md-3 "
                    }"relatedListOuterDiv border-top-0"`}
                  badge={{
                    count:
                      IsNotNullOrWhiteSpace(
                        state.careInterventionSupportItems
                      ) &&
                        IsNotNullOrWhiteSpace(
                          state.careInterventionSupportItems.length
                        )
                        ? state.careInterventionSupportItems.length
                        : 0,
                    show: true,
                    className:
                      "ms-2 raf-badge-circle raf-badge-tertiary-circle",
                  }}
                  customButton={
                    showAddBtn ? (
                      <RAFPermissionRender
                        permissionName={
                          CareInterventionSupportPermissionConstants.CareInterventionSupportAdd
                        }
                      >
                        {uiStyle === "CollapsePanelWithOutCardStyle" ? (
                          <RAFButtonComponent
                            className={"link-button text-decoration-underline"}
                            // className="custom-button-md"
                            action={RAFButtonConstant.Add}
                            // btnContent={`Add Task`}
                            onClick={() => onClickAddCareInterventionSupport()}
                            btnContent="Add Task"
                            showIcon={false}
                          />
                        ) : (
                          <RAFButtonComponent
                            className={"custom-button-sm primary-custom-button"}
                            // className="custom-button-md"
                            action={RAFButtonConstant.Add}
                            // btnContent={`Add Task`}
                            onClick={() => onClickAddCareInterventionSupport()}
                          />
                        )}
                      </RAFPermissionRender>
                    ) :
                      (
                        ""
                      )
                  }
                >
                  <div>
                    {createCareInterventionSupportDlgDiv()}
                    {careInterventionSupportListItem()}
                  </div>
                </RAFCollapseWithPlusIcon>
              </div>
            </div>
          </div>
        );
      } else if (uiStyle === "SummaryStyle") {
        return (
          <CustomCardWidget cardContentClassName="p-0" key={supportNeedCount}>
            <RAFCollapseWithPlusIcon
              toggleArrowIcon="Arrow"
              //title="Support Needs"
              title="Tasks"
              collapsePanelHeaderClass={
                "collapsePanel__header__fixedHeight__lg"
              }
              badge={{
                count: supportNeedCount,
                show: true,
                className: "ms-2 raf-badge-circle raf-badge-tertiary-circle",
              }}
              isCollapsed={supportNeedCount > 0 ? false : true}
              collapsible={supportNeedCount > 0 ? true : false}
              collapsePanelContentClassname={paddingClass}
            >
              {supportNeedsItemSummary()}
            </RAFCollapseWithPlusIcon>
          </CustomCardWidget>
        );
      } else if (uiStyle === "SummaryDetails") {
        return (
          <CustomCardWidget cardContentClassName="p-0">
            <RAFCollapseWithPlusIcon
              toggleArrowIcon="Arrow"
              //title="Support Needs"
              title="Tasks"
              collapsePanelHeaderClass={
                "collapsePanel__header__fixedHeight__lg"
              }
              allowFullRowClick
              // collapsePanelContentClassname="p-0"
              badge={{
                count: supportNeedCount,
                show: true,
                className: "ms-2 raf-badge-circle raf-badge-tertiary-circle",
              }}
              isCollapsed={false}
              collapsible={true}
              showSeparator={true}
              customButton={
                showAddBtn ? (
                  <RAFPermissionRender
                    permissionName={
                      CareInterventionSupportPermissionConstants.CareInterventionSupportAdd
                    }
                  >
                    <RAFButtonComponent
                      className="custom-button-sm primary-custom-button"
                      // className="custom-button-md"
                      action={RAFButtonConstant.Add}
                      // btnContent={`Add Task`}
                      onClick={() => onClickAddCareInterventionSupport()}
                    />
                  </RAFPermissionRender>
                ) : (
                  ""
                )
              }
            >
              {createCareInterventionSupportDlgDiv()}
              {careInterventionSupportListItem()}
            </RAFCollapseWithPlusIcon>
          </CustomCardWidget>
        );
      } else {
        const showExpandBtn = state.isLoading
          ? true
          : isNotEmptyArray(state.careInterventionSupportItems)
            ? true
            : false;
        return (
          <div className="col-12">
            <div
              className={`${isNotNullAndUndefined(props.customCardClass) &&
                `${props.customCardClass} `
                }custom__card`}
            >
              <div className="custom__card__content p-0">
                <div className="py-2 header-text-sm border-top">
                  {/* {CareEsioEntity.CareInterventionSupport.CollectionName} */}
                  Tasks
                </div>
                {createCareInterventionSupportDlgDiv()}
                {careInterventionSupportListItem()}

                {/* <RAFCollapse
                  title={CareEsioEntity.CareInterventionSupport.CollectionName}
                  headerRowClassName="with-height bg-card-header-5"
                  contentCardClassName="relatedListOuterDiv"
                  headerBadge={
                    isNotEmptyArray(state.careInterventionSupportItems) && (
                      <span className="e-badge e-badge-grey e-badge-notification ms-2 position-static pb-1">
                        {state.careInterventionSupportItems.length}
                      </span>
                    )
                  }
                  removeContentCardPadding
                  IsCollapsed={true}
                  customButton={
                    <div>
                      {props.showAddBtn !== false && (
                        <div className="d-flex align-items-baseline">
                          <div className="col-auto">
                            <RAFButtonComponent
                              className="custom-button-sm primary-custom-button"
                              // className="custom-button-md"
                              action={RAFButtonConstant.Add}
                              btnContent={`Add ${CareEsioEntity.CareInterventionSupport.DisplayName}`}
                              onClick={() =>
                                onClickAddCareInterventionSupport()
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  }
                  Collapsible={showExpandBtn}
                >
                  {createCareInterventionSupportDlgDiv()}
                  {careInterventionSupportListItem()}
                </RAFCollapse> */}
              </div>
            </div>
          </div>
        );
      }
    };

    const createCareInterventionSupportDlgDiv = () => {
      return (
        state.showCreateCareInterventionSupportContent && (
          <DialogComponent
            header={`Add Task`}
            // header={
            //     "Add " +
            //     getDisplayNameByModuleName(careInterventionSupportModule, state.careInterventionSupportEntity.DisplayName)
            // }
            showCloseIcon
            visible={state.showCreateCareInterventionSupportContent}
            cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
            id={`dlg_update_${careInterventionSupportModule}`}
            content={createCareInterventionSupportContent.bind(this)}
            isModal
            target="body"
            closeOnEscape={false}
            close={closeCreateCareInterventionSupportDialog.bind(this)}
            zIndex={1200}
            ref={addTaskDialogRef}
            open={PreventFocusOnDialogOpen}
          ></DialogComponent>
        )
      );
    };

    //addCareInterventionSupport start
    const onClickAddCareInterventionSupport = async () => {
      setState({
        showCreateCareInterventionSupportContent: true,
      });
    };

    const createCareInterventionSupportContent = () => {
      if (state.showCreateCareInterventionSupportContent) {
        const { careAssessmentRow } = props;
        let initialValues = {};
        if (isNotNullAndUndefined(careAssessmentRow)) {
          initialValues["CareRecipientUID"] =
            careAssessmentRow.CareRecipientUID;
          initialValues["CareRecipientUID"] =
            careAssessmentRow["care_recipientuid"];
          initialValues["CareRecipient"] = careAssessmentRow["care_recipient"];
          initialValues["CarePlan"] = careAssessmentRow["care_plan"];
          initialValues["CarePlanUID"] = careAssessmentRow["care_planuid"];
          initialValues["Category"] = careAssessmentRow["category"];
          initialValues["CategoryUID"] = careAssessmentRow["categoryuid"];

          initialValues["CareAssessment"] = careAssessmentRow["title"];
          initialValues["CareAssessmentUID"] = careAssessmentRow["UID"];
          initialValues["CareAssessmentType"] =
            CareEsioEntity.CareAssessment.EntityName;
          //initialValues["SupportType"] = "Assist";
        } else {
          const objectContext = React.useContext(RAFObjectContext);
          const rafObject = isNotNullAndUndefined(objectContext)
            ? objectContext.rafObject
            : null;
          if (isNotNullAndUndefined(rafObject)) {
            initialValues["CareRecipientUID"] = rafObject.UID;
            initialValues["CareRecipient"] = rafObject.first_name;
            initialValues["CareAssessmentUID"] = null;
          }
        }

        return (
          <CreateCareInterventionSupport
            initialValues={initialValues}
            onSave={onCreateCareInterventionSupportList.bind(this)}
            onClose={closeCreateCareInterventionSupportDialog.bind(this)}
            moduleName={careInterventionSupportModule}
            isActive
          />
        );
      } else {
        return <div></div>;
      }
    };

    const onCreateCareInterventionSupportList = () => {
      refresh();
    };

    const closeCreateCareInterventionSupportDialog = async () => {
      await RafClearDialogContent(addTaskDialogRef);
      setState({ showCreateCareInterventionSupportContent: false });
    };

    //addCareInterventionSupport end

    const careInterventionSupportListItem = () => {
      if (state.isLoading === false) {
        if (isNotEmptyArray(state.careInterventionSupportItems)) {
          if (uiStyle === "ListStyle") {
            return state.careInterventionSupportItems.map((item) => {
              return (
                <li className="list-group-item p-0">
                  <CareInterventionSupportContent
                    key={item.UID}
                    careInterventionSupportRow={item}
                    careInterventionSupportEntity={
                      state.careInterventionSupportEntity
                    }
                    onSave={refresh}
                    showCheckBox={props.showCheckBox}
                    careAssessmentUID={props.careAssessmentUID}
                  />
                </li>
              );
            });
          } else {
            return (
              <div className="col-12">
                <div className="custom__card__content p-0">
                  <div className="row g-0 gy-2 gy-md-3">
                    {state.careInterventionSupportItems.map((item) => {
                      return (
                        <CareInterventionSupportContent
                          key={item.UID}
                          careInterventionSupportRow={item}
                          careInterventionSupportEntity={
                            state.careInterventionSupportEntity
                          }
                          onSave={refresh}
                          uiStyle={
                            uiStyle === "CollapsePanelStyle"
                              ? "CardStyle"
                              : "CollapsePanelStyle"
                          }
                          allowEdit={
                            uiStyle === "SummaryStyle" ? false : allowEdit
                          }
                          showCheckBox={props.showCheckBox}
                          parent="CreateSupportTask"
                          careAssessmentUID={props.careAssessmentUID}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          }
        } else if (showEmptyState !== false) {
          return (
            <div className="col-12">
              <RAFEmptyState
                //title={`No ${CareEsioEntity.CareInterventionSupport.DisplayName} recorded.`}
                title={`No task recorded.`}
                {...(showAddBtn === true && {
                  body: `To get started, please add a new task by clicking on the "Add" button.`,
                })}
              />
            </div>
          );
        } else {
          return;
        }
      } else {
        return (
          <div className="col-12">
            <div className="custom__card__content p-0">
              <div className="row g-3">
                <div className="container-fluid px-0">
                  <ACLoadingPanel loadingText="Preparing Data..." />
                </div>
              </div>
            </div>
          </div>
        );
      }
    };

    const getBodyContent = () => {
      if (
        isNotNullAndUndefined(props.careAssessmentUID) ||
        isNotNullAndUndefined(props.careRecipientUID)
      ) {
        if (showEmptyState === false) {
          if (state.isLoading) {
            return (
              <div className="p-3">
                <div className="row g-3">
                  <div className="container-fluid px-0">
                    <ACLoadingPanel loadingText="Preparing Data..." />
                  </div>
                </div>
              </div>
            );
          } else {
            // if (isNotEmptyArray(state.careInterventionSupportItems)) {
            return getCareInterventionSupportListContent();
            // } else {
            //   return <div>{createCareInterventionSupportDlgDiv()}</div>;
            // }
          }
        } else {
          return getCareInterventionSupportListContent();
        }
      } else {
        return <div></div>;
      }
    };

    return (
      <RAFPermissionRender
        permissionName={
          CareInterventionSupportPermissionConstants.CareInterventionSupportRead
        }
      >
        {getBodyContent()}
      </RAFPermissionRender>
    );
  }
);

export default React.memo(CareInterventionSupportList);
