import * as R from "ramda";
import React, {
  PropsWithChildren,
  Reducer,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { IconPickerItem } from "react-fa-icon-picker";
import { FormRenderProps } from "react-final-form";
import {
  RAFFormContext,
  setFormValue,
} from "../../RAFComponents/Inputs/RFFUtils";
import RAFCollapse from "../../RAFComponents/Navigation/RAFCollapse";
import RAFCollapseWithPlusIcon from "../../RAFComponents/Navigation/RAFCollapseWithPlusIcon";
import RAFDeletedRecordState from "../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFIconImage from "../../RAFComponents/Navigation/RAFIconImage";
import {
  hideProgress,
  showProgress,
} from "../../RAFComponents/helpers/AppHelper";
import { RAFLayout } from "../../constants/Common/Constants";
import {
  isEmptyOrNull,
  isNotEmptyArray,
  isNotNullAndUndefined,
  isNullOrUndefined,
} from "../../RAFComponents/helpers/utils";
import { getAllBusinessProcessType } from "../../RAFMaster/RMModules/Category/BusinessProcessTypeHelper";
import { BusinessProcessTypeRow } from "../../RAFMaster/RMModules/Category/BusinessProcessTypeRow";
interface IProps {
  isActive?: boolean;
  categorySelected?: (categoryUID?: string) => void;
  entityName?: string;
  progressDivId?: string;
  objectRows?: any;
}

interface IState {
  selectedItemIndex: number;
  allCategoryList: BusinessProcessTypeRow[];
  allCategories: BusinessProcessTypeRow[];
  subCategories: BusinessProcessTypeRow[];
  isLoading: boolean;
}

function CareCategoryAndSubCategory({ ...props }: PropsWithChildren<IProps>) {
  const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      allCategories: null,
      allCategoryList: null,
      subCategories: null,
      selectedItemIndex: 0,
    }
  );

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    if (props.isActive) {
      let progressDiv = isNotNullAndUndefined(props.progressDivId)
        ? showProgress(props.progressDivId)
        : showProgress("body", true);
      const allCategoryList = await getAllBusinessProcessType(props.entityName);
      let allCategoriesListUpdated = getUpdatedCategory(
        props.objectRows,
        allCategoryList
      );

      let allCategories = isNotEmptyArray(allCategoriesListUpdated)
        ? allCategoriesListUpdated.filter((x) => isNullOrUndefined(x.ParentUID))
        : [];

      setState({
        allCategories,
        allCategoryList: allCategoriesListUpdated,
        isLoading: false,
      });
      hideProgress(progressDiv);
    }
  };

  const getUpdatedCategory = (
    objectRows: any,
    allCategories: BusinessProcessTypeRow[]
  ) => {
    if (isNotNullAndUndefined(objectRows) && objectRows.length > 0) {
      objectRows.forEach((element) => {
        allCategories.forEach((category) => {
          if (element.SubCategoryUID === category.UID) {
            //category.IsVisible = false;
            const index = allCategories.findIndex(
              (x) => x.UID === category.UID
            );
            if (index !== -1) {
              allCategories.splice(index, 1);
            }
          }
        });
      });
    }
    return allCategories;
  };

  const onDoubleClickCategoryItem = (item: BusinessProcessTypeRow) => {
    updateCategoryFormValue(item);
    let { allCategoryList } = state;
    let subCategories = [];
    if (isNotEmptyArray(allCategoryList)) {
      subCategories = allCategoryList.filter((x) => x.ParentUID === item.UID);
    }
    if (isNotEmptyArray(subCategories)) {
      setState({ selectedItemIndex: 1, subCategories });
    } else {
      //showWarningToast("Subcategories does not exist for this item.");
      if (props.categorySelected) {
        props.categorySelected();
      }
    }
  };

  const onDoubleClickSubCategoryItem = async (item: BusinessProcessTypeRow) => {
    if (isNotNullAndUndefined(item)) {
      // let progressDiv = isNotNullAndUndefined(props.progressDivId)
      //   ? showProgress(props.progressDivId)
      //   : showProgress("body", true);
      //const allForms = await getAssessmentFormsByCategoryId(item.UID);
      // hideProgress(progressDiv);
      // if (isNotEmptyArray(allForms)) {
      //   updateSubCategoryFormValue(item);
      //   if (props.categorySelected) {
      //     props.categorySelected(item.UID);
      //   }
      // } else {
      //   showWarningToast("Form does not exist for this item.");
      // }
      updateSubCategoryFormValue(item);
      if (props.categorySelected) {
        props.categorySelected(item.UID);
      }
    }
  };

  const updateCategoryFormValue = (item: BusinessProcessTypeRow) => {
    if (isNotNullAndUndefined(item)) {
      setFormValue(rafFormContextValue, "CategoryUID", item.UID);
      setFormValue(rafFormContextValue, "Category", item.Title);
    } else {
      setFormValue(rafFormContextValue, "CategoryUID", null);
      setFormValue(rafFormContextValue, "Category", null);
    }
  };

  const updateSubCategoryFormValue = (item: BusinessProcessTypeRow) => {
    if (isNotNullAndUndefined(item)) {
      setFormValue(rafFormContextValue, "SubCategoryUID", item.UID);
      setFormValue(rafFormContextValue, "SubCategory", item.Title);
      setFormValue(rafFormContextValue, "Icon", item.Icon);
    } else {
      setFormValue(rafFormContextValue, "SubCategoryUID", null);
      setFormValue(rafFormContextValue, "SubCategory", null);
      setFormValue(rafFormContextValue, "Icon", null);
    }
  };

  const updateCategoryAndSubCategoryFormValue = (
    item: BusinessProcessTypeRow
  ) => {
    if (isNotNullAndUndefined(item)) {
      setFormValue(rafFormContextValue, "CategoryUID", item.ParentUID);
      setFormValue(rafFormContextValue, "Category", item.Parent);
      setFormValue(rafFormContextValue, "SubCategoryUID", item.UID);
      setFormValue(rafFormContextValue, "SubCategory", item.Title);
      setFormValue(rafFormContextValue, "Icon", item.Icon);
    } else {
      setFormValue(rafFormContextValue, "CategoryUID", null);
      setFormValue(rafFormContextValue, "Category", null);
      setFormValue(rafFormContextValue, "SubCategoryUID", null);
      setFormValue(rafFormContextValue, "SubCategory", null);
      setFormValue(rafFormContextValue, "Icon", null);
    }
  };

  const onSubCategoryItemSelected = async (item: BusinessProcessTypeRow) => {
    if (isNotNullAndUndefined(item)) {
      // let progressDiv = isNotNullAndUndefined(props.progressDivId)
      //   ? showProgress(props.progressDivId)
      //   : showProgress("body", true);
      // hideProgress(progressDiv);

      updateCategoryAndSubCategoryFormValue(item);
      if (props.categorySelected) {
        props.categorySelected(item.UID);
      }
    }
  };

  const onClickBackBtn = () => {
    setState({ selectedItemIndex: 0 });
  };

  if (props.isActive) {
    if (state.isLoading === false) {
      return (
        <div>
          <div className="hidden">
            {state.selectedItemIndex === 0 && (
              <div className="">
                <div className="mb-1">
                  <label className="form-label">Choose Category</label>
                </div>
                {isNotEmptyArray(state.allCategories) && (
                  <div className="row g-3 mb-3">
                    {state.allCategories.map((item, index) => {
                      const iconName = item.Icon;
                      return (
                        <div
                          className={"col-6"}
                          key={index}
                          // onClick={() => onClickCategoryItem(item)}
                          onClick={() => onDoubleClickCategoryItem(item)}
                        >
                          <div
                            className="custom__card clickable"
                            id={`card__${item.UID}`}
                          >
                            <div className="custom__card__content p-2 darkText">
                              <div className="d-flex align-items-center">
                                {isEmptyOrNull(iconName) ? (
                                  <RAFIconImage
                                    iconCssClass={
                                      "far fa-hand-holding-seedling"
                                    }
                                    moduleavatar="transparent-avatar avatar-text avatar-text-square"
                                    iconSize="30"
                                    fontSize="16"
                                  />
                                ) : (
                                  <div>
                                    <span
                                      className="border-avatar avatar-text avatar-text-square"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        minWidth: "30px",
                                        maxWidth: "30px",
                                        fontSize: "16px",
                                      }}
                                    >
                                      <IconPickerItem
                                        icon={iconName}
                                        size={16}
                                      />
                                    </span>
                                  </div>
                                )}
                                <span className="secondary-header-bold-text-withoutColor ps-2 ecllipseSecondLine">
                                  {" "}
                                  {item.Title}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {!isNotEmptyArray(state.allCategories) && (
                  <div className="p-3 py-2 columnChooserPanel customScrollBar">
                    <RAFDeletedRecordState title="You do not have sufficient privileges to add this item." />
                  </div>
                )}
              </div>
            )}
            {state.selectedItemIndex === 1 && (
              <div className="">
                <div className="d-flex mb-2">
                  <div
                    className="backArrowIcon backArrowIcon-md"
                    onClick={() => onClickBackBtn()}
                  >
                    <i className="fass fa-arrow-left-long"></i>
                  </div>
                  <div>
                    <label className="form-label">Choose Sub-category</label>
                  </div>
                </div>
                {isNotEmptyArray(state.subCategories) && (
                  <div className="row g-3 mb-3">
                    {state.subCategories.map((item, index) => {
                      const iconName = item.Icon;
                      return (
                        <div
                          // className={
                          //   isNotNullAndUndefined(item.IsVisible) &&
                          //   item.IsVisible === false
                          //     ? "col-6 disabled"
                          //     : "col-6"
                          // }
                          className={"col-6"}
                          key={index}
                          // onClick={() => onClickCategoryItem(item)}
                          onClick={() => onDoubleClickSubCategoryItem(item)}
                        >
                          <div
                            className="custom__card clickable"
                            id={`card__${item.UID}`}
                          >
                            <div className="custom__card__content p-2 darkText">
                              <div className="d-flex align-items-center">
                                {isEmptyOrNull(iconName) ? (
                                  <RAFIconImage
                                    iconCssClass={
                                      "far fa-hand-holding-seedling"
                                    }
                                    moduleavatar="transparent-avatar avatar-text avatar-text-square"
                                    iconSize="30"
                                    fontSize="16"
                                  />
                                ) : (
                                  <div>
                                    <span
                                      className="border-avatar avatar-text avatar-text-square"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        minWidth: "30px",
                                        maxWidth: "30px",
                                        fontSize: "16px",
                                      }}
                                    >
                                      <IconPickerItem
                                        icon={iconName}
                                        size={16}
                                      />
                                    </span>
                                  </div>
                                )}
                                <span className="secondary-header-bold-text-withoutColor ps-2 ecllipseSecondLine">
                                  {" "}
                                  {item.Title}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {!isNotEmptyArray(state.subCategories) && (
                  <div className="p-3 py-2 columnChooserPanel customScrollBar">
                    <RAFDeletedRecordState title="You do not have sufficient privileges to add this item." />
                  </div>
                )}
              </div>
            )}
          </div>
          <div>
            {isNotEmptyArray(state.allCategories) &&
              state.allCategories.map((category) => {
                let allCategoryList: BusinessProcessTypeRow[] = R.clone(
                  state.allCategoryList
                );
                let subcategories: BusinessProcessTypeRow[] =
                  allCategoryList.filter((x) => x.ParentUID === category.UID);
                if (isNotEmptyArray(subcategories)) {
                  return (
                    <div key={category.UID} style={{ paddingBottom: '1px' }}>
                      {false &&
                        <RAFCollapse
                          title={category.Title}
                          headerRowClassName="with-height"
                          contentCardClassName="relatedListOuterDiv"
                          iconName={category.Icon}
                          IsCollapsed={false}
                        >
                          <div className="row g-3 mb-3">
                            {subcategories.map((subCategory) => {
                              if (subCategory.ParentUID === category.UID) {
                                return (
                                  <div
                                    className={"col-6"}
                                    key={subCategory.UID}
                                    onClick={() =>
                                      onSubCategoryItemSelected(subCategory)
                                    }
                                  >
                                    <div
                                      className="custom__card clickable"
                                      id={`card__${subCategory.UID}`}
                                    >
                                      <div className="custom__card__content p-2 darkText">
                                        <div className="d-flex align-items-center">
                                          {isEmptyOrNull(subCategory.Icon) ? (
                                            <RAFIconImage
                                              iconCssClass={
                                                "far fa-hand-holding-seedling"
                                              }
                                              moduleavatar="transparent-avatar avatar-text avatar-text-square"
                                              iconSize="30"
                                              fontSize="16"
                                            />
                                          ) : (
                                            <div>
                                              <span
                                                className="border-avatar avatar-text avatar-text-square"
                                                style={{
                                                  width: "30px",
                                                  height: "30px",
                                                  minWidth: "30px",
                                                  maxWidth: "30px",
                                                  fontSize: "16px",
                                                }}
                                              >
                                                <IconPickerItem
                                                  icon={subCategory.Icon}
                                                  size={16}
                                                />
                                              </span>
                                            </div>
                                          )}
                                          <span className="secondary-header-bold-text-withoutColor ps-2 ecllipseSecondLine">
                                            {" "}
                                            {subCategory.Title}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </RAFCollapse>
                      }
                      <RAFCollapseWithPlusIcon
                        title={category.Title}
                        layout={RAFLayout.TwoColumnLayout}
                        collapsePanelHeaderClass={'collapsePanel__header__fixedHeight bg-light-blue-3'}
                        headerFixedHeight
                        expandedStateIcon="fas fa-chevron-up small-icon"
                        collapsedStateIcon="fas fa-chevron-down small-icon"
                        allowFullRowClick
                      >
                        {subcategories.map((subCategory) => {
                          if (subCategory.ParentUID === category.UID) {
                            return (
                              <div
                                className="custom__card clickable"
                                id={`card__${subCategory.UID}`}
                                key={subCategory.UID}
                                onClick={() =>
                                  onSubCategoryItemSelected(subCategory)
                                }
                              >
                                <div className="custom__card__content p-2 darkText">
                                  <div className="d-flex align-items-center">
                                    {isEmptyOrNull(subCategory.Icon) ? (
                                      <RAFIconImage
                                        iconCssClass={
                                          "far fa-hand-holding-seedling"
                                        }
                                        moduleavatar="transparent-avatar avatar-text avatar-text-square"
                                        iconSize="30"
                                        fontSize="16"
                                      />
                                    ) : (
                                      <div>
                                        <span
                                          className="border-avatar avatar-text avatar-text-square"
                                          style={{
                                            width: "30px",
                                            height: "30px",
                                            minWidth: "30px",
                                            maxWidth: "30px",
                                            fontSize: "16px",
                                          }}
                                        >
                                          <IconPickerItem
                                            icon={subCategory.Icon}
                                            size={16}
                                          />
                                        </span>
                                      </div>
                                    )}
                                    <span className="secondary-header-bold-text-withoutColor ps-2 ecllipseSecondLine">
                                      {" "}
                                      {subCategory.Title}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </RAFCollapseWithPlusIcon>
                    </div>
                  );
                }
              })}
          </div>
        </div>
      );
    }
  }
}
export default React.memo(CareCategoryAndSubCategory);
