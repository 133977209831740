import { DialogUtility } from "@syncfusion/ej2-popups";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import React, {
  PropsWithChildren,
  Reducer,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from "react";
import { RolePermissionsContext } from "../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import { PreventFocusOnDialogOpen } from "../../../RAFComponents/Dialog/SFDialogUtils";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFCollapse from "../../../RAFComponents/Navigation/RAFCollapse";
import RAFDetailFieldCustom from "../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import RAFRecordInfo from "../../../RAFComponents/Navigation/RAFRecordInfo";
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  DeleteRecord,
  RafClearDialogContent,
  hideProgress,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender, { hasPermission } from "../../../RAFComponents/helpers/PermissionHelper";
import {
  ConvertSystemName,
  IsNotNullOrWhiteSpace,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import { EntityRow } from "../../../RAFMaster/RMModules/Entity/EntityRow";
import { getEntityByObjectName } from "../../../RAFMaster/helpers/RMutils";
import {
  CareGoalPermissionConstants,
  CareInterventionSupportPermissionConstants,
} from "../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  BrowserIsDevice,
  MomentFormats,
  RAFButtonConstant,
  RAFLayout,
} from "../../../constants/Common/Constants";
import CareInterventionSupportContent from "../CareInterventionSupport/CareInterventionSupportContent";
import { getCareInterventionSupportListByGoalUID } from "../CareInterventionSupport/CareInterventionSupportHelper";
import CreateCareInterventionSupport from "../CareInterventionSupport/CreateCareInterventionSupport";
import UpdateCareGoal from "./UpdateCareGoal";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFDetailsValueWithSeparator from "../../../RAFComponents/Navigation/RAFDetailsValueWithSeparator/RAFDetailsValueWithSeparator";
import { RAFDataType } from "../../../RAFComponents/models/Common/RAFDataType";
import RAFCollapseWithPlusIcon from "../../../RAFComponents/Navigation/RAFCollapseWithPlusIcon";

interface IProps {
  careGoalRow: any;
  careRecipientUID: string;
  careRecipientName: string;
  onSave: () => void;
  careGoalEntity: EntityRow;
  isSubGoal?: boolean;
  subGoalItems?: any[];
  onClickAddSubGoal?: (parentUID: string) => void;
}

interface IState {
  showUpdateCareGoal: boolean;
  relatedSupportTasks: any[];
  careInterventionSupportEntity: EntityRow;
  showCreateCareInterventionSupportContent: boolean;
}

function CareGoalContent({ careGoalRow, ...props }: PropsWithChildren<IProps>) {
  let careGoalContentDialogRef = useRef<DialogComponent>(null);
  let deleteDialog: any;
  const careGoalModule = CareEsioEntity.CareGoal.EntityName;
  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext) ? rolePermissionsContext.permissionValue : null;
  const hasPermissionToEdit = hasPermission(permissionValue, `${careGoalModule}::Update`);

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      showUpdateCareGoal: false,
      relatedSupportTasks: [],
      careInterventionSupportEntity: null,
      showCreateCareInterventionSupportContent: false,
    }
  );

  useEffect(() => {
    refresh();
  }, [careGoalRow.UID]);

  const refresh = async () => {
    await RafClearDialogContent(careGoalContentDialogRef);
    const relatedSupportTasks = await getCareInterventionSupportListByGoalUID(
      careGoalRow.UID,
      CareEsioEntity.CareInterventionSupport.EntityName
    );

    const careInterventionSupportEntity = await getEntityByObjectName({
      ObjectName: ConvertSystemName(
        CareEsioEntity.CareInterventionSupport.EntityName
      ),
    });
    setState({
      relatedSupportTasks,
      careInterventionSupportEntity,
      showCreateCareInterventionSupportContent: false,
      showUpdateCareGoal: false,
    });
  };

  const onClickAddSubGoal = () => {
    if (props.onClickAddSubGoal) {
      props.onClickAddSubGoal(careGoalRow.UID);
    }
  };

  //UpdateCareGoal start
  const showUpdateUpdateCareGoalDialog = () => {
    if (hasPermissionToEdit) {
      setState({ showUpdateCareGoal: true });
    }
  };

  const updateUpdateCareGoalContent = () => {
    if (state.showUpdateCareGoal) {
      return (
        <UpdateCareGoal
          objectUID={careGoalRow.UID}
          moduleName={careGoalModule}
          onSave={refreshOnUpdate.bind(this)}
          onClose={closeUpdateCareGoalDialog.bind(this)}
          onDelete={onClickDeleteSection}
          isActive
        />
      );
    } else {
      return <div></div>;
    }
  };

  const refreshOnUpdate = async () => {
    await RafClearDialogContent(careGoalContentDialogRef);
    setState({ showUpdateCareGoal: false });
    if (props.onSave) {
      props.onSave();
    }
  };

  const closeUpdateCareGoalDialog = async () => {
    await RafClearDialogContent(careGoalContentDialogRef);
    setState({ showUpdateCareGoal: false });
  };
  //UpdateCareGoal end

  //delete item start
  const onClickDeleteSection = () => {
    deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Are you sure want to delete?",
      okButton: { text: "Yes", click: deleteRecord.bind(this) },
      title: `Delete ${CareEsioEntity.CareGoal.DisplayName}`,
      position: { X: "center", Y: "center" },
      cssClass: `alert-dialog deleteDialog`,
    });
  };

  async function deleteRecord() {
    let progressDiv = showProgress(".deleteDialog.e-dialog");
    let isDeleted = await DeleteRecord(
      careGoalRow.UID,
      careGoalModule,
      props.careGoalEntity.UID
    );

    if (isDeleted) {
      hideProgress(progressDiv);
      deleteDialog.hide();
      refreshOnUpdate();
    } else {
      hideProgress(progressDiv);
      deleteDialog.hide();
      showWarningToast("Sorry something went wrong !");
    }
  }
  //delete item end

  const subGoalAddBtn = () => {
    return (
      props.isSubGoal !== true && (
        <div className="col-auto">
          <RAFButtonComponent
            className={`custom-button-sm primary-custom-button`}
            // className={`custom-button-sm primary-custom-button${!BrowserIsDevice ? " min-width-lg" : ""}`}
            // className="custom-button-md"
            action={RAFButtonConstant.Add}
            // {...!BrowserIsDevice ?
            //     { btnContent: `Add Sub-${CareEsioEntity.CareGoal.DisplayName}` }
            //     :
            //     {}
            // }
            onClick={onClickAddSubGoal}
          />
        </div>
      )
    );
  };

  const getSubGoalItems = () => {
    const { subGoalItems } = props;
    return (
      // <div className="pt-0 pb-1 p-2 p-md-3 pt-md-1 pb-md-2">
      <RAFCollapse
        title={`Sub ${CareEsioEntity.CareGoal.CollectionName}`}
        headerRowClassName="sectionHeader-xsm with-height border"
        contentCardClassName="relatedListOuterDiv p-2 p-md-3"
        headerBadge={
          isNotEmptyArray(subGoalItems) && (
            <div className="secondary-text">
              {" "}
              <span className="e-badge e-badge-grey-border e-badge-notification e-badge-circle e-badge-md ms-2 position-static">
                {subGoalItems.length}
              </span>
            </div>
          )
        }
        IsCollapsed={true}
        contentRowClassName="row g-0 gy-3"
        removeContentCardPadding
        customButton={subGoalAddBtn()}
      >
        {subGoalItems.map((item) => {
          return (
            <div className="col-12" key={item.UID}>
              <CareGoalContent
                key={item.UID}
                careGoalRow={item}
                careGoalEntity={props.careGoalEntity}
                careRecipientUID={props.careRecipientUID}
                careRecipientName={props.careRecipientName}
                onSave={props.onSave}
                isSubGoal
              />
            </div>
          );
        })}
      </RAFCollapse>
      // </div>
    );
  };

  //addCareInterventionSupport start
  const onClickAddCareInterventionSupport = async () => {
    setState({
      showCreateCareInterventionSupportContent: true,
    });
  };

  const createCareInterventionSupportContent = () => {
    if (state.showCreateCareInterventionSupportContent) {
      let initialValues = {};
      if (isNotNullAndUndefined(careGoalRow)) {
        initialValues["CareRecipientUID"] = props.careRecipientUID;
        initialValues["CareRecipient"] = props.careRecipientName;
        initialValues["GoalUID"] = careGoalRow.UID;
        initialValues["Goal"] = careGoalRow.Title;
      }

      return (
        <CreateCareInterventionSupport
          initialValues={initialValues}
          onSave={onCreateCareInterventionSupportList.bind(this)}
          onClose={closeCreateCareInterventionSupportDialog.bind(this)}
          moduleName={CareEsioEntity.CareInterventionSupport.EntityName}
          isActive
        />
      );
    } else {
      return <div></div>;
    }
  };

  const onCreateCareInterventionSupportList = () => {
    refresh();
  };

  const closeCreateCareInterventionSupportDialog = async () => {
    await RafClearDialogContent(careGoalContentDialogRef);
    setState({ showCreateCareInterventionSupportContent: false });
  };

  const createCareInterventionSupportDlgDiv = () => {
    return (
      state.showCreateCareInterventionSupportContent && (
        <DialogComponent
          header={`Add Task`}
          // header={
          //     "Add " +
          //     getDisplayNameByModuleName(careInterventionSupportModule, state.careInterventionSupportEntity.DisplayName)
          // }
          showCloseIcon
          visible={state.showCreateCareInterventionSupportContent}
          cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
          id={`dlg_update_${CareEsioEntity.CareInterventionSupport.EntityName}`}
          content={createCareInterventionSupportContent.bind(this)}
          isModal
          target="body"
          closeOnEscape={false}
          close={closeCreateCareInterventionSupportDialog.bind(this)}
          zIndex={1200}
          open={PreventFocusOnDialogOpen}
          ref={careGoalContentDialogRef}
        />
      )
    );
  };

  //addCareInterventionSupport end

  const getRelatedSupportTasks = () => {
    const { relatedSupportTasks, careInterventionSupportEntity } = state;
    return (
      <div
        onClick={(e) => e.stopPropagation()}
      >
        <RAFCollapseWithPlusIcon
          toggleArrowIcon="Arrow"
          title={CareEsioEntity.CareInterventionSupport.CollectionName}
          collapsePanelHeaderSpanClass="subtitle_1"
          collapsePanelHeaderClass="px-0"
          headerFixedHeight
          allowFullRowClick
          showSeparator={false}
          layout={RAFLayout.OneColumnLayout}
          collapsePanelContentClassname={'p-0'}
          customButton={
            <RAFPermissionRender
              permissionName={
                CareInterventionSupportPermissionConstants.CareInterventionSupportAdd
              }
            >
              <RAFButtonComponent
                className="custom-button-sm btn_brand_secondary outline"
                // className="custom-button-md"
                action={RAFButtonConstant.Add}
                // btnContent={`Add Task`}
                onClick={() => onClickAddCareInterventionSupport()}
              />
            </RAFPermissionRender>
          }
          badge={{
            count:
              IsNotNullOrWhiteSpace(
                relatedSupportTasks
              ) &&
                IsNotNullOrWhiteSpace(
                  relatedSupportTasks.length
                )
                ? relatedSupportTasks.length
                : 0,
            show: true,
            className:
              "ms-2 raf-badge-circle raf-badge-tertiary-circle",
          }}
        >
          {relatedSupportTasks.map((item) => {
            return (
              <div
                className="col-12"
                key={item.UID}
                style={{ cursor: "pointer" }}
              >
                <CareInterventionSupportContent
                  key={item.UID}
                  careInterventionSupportRow={item}
                  careInterventionSupportEntity={
                    careInterventionSupportEntity
                  }
                  onSave={refresh}
                  isRelatedSection
                />
              </div>
            );
          })}
          {createCareInterventionSupportDlgDiv()}
        </RAFCollapseWithPlusIcon>
        {false && (
          <RAFCollapse
            title={CareEsioEntity.CareInterventionSupport.CollectionName}
            headerRowClassName="sectionHeader-xsm"
            removeContentCardPadding
            contentCardClassName="relatedListOuterDiv p-2 p-md-3"
            headerBadge={
              isNotEmptyArray(relatedSupportTasks) && (
                <div className="secondary-text">
                  {" "}
                  <span className="e-badge e-badge-grey-border e-badge-notification e-badge-circle e-badge-md ms-2 position-static">
                    {relatedSupportTasks.length}
                  </span>
                </div>
              )
            }
            IsCollapsed={true}
            customButton={
              <RAFPermissionRender
                permissionName={
                  CareInterventionSupportPermissionConstants.CareInterventionSupportAdd
                }
              >
                <RAFButtonComponent
                  className="custom-button-sm primary-custom-button"
                  // className="custom-button-md"
                  action={RAFButtonConstant.Add}
                  // btnContent={`Add Task`}
                  onClick={() => onClickAddCareInterventionSupport()}
                />
              </RAFPermissionRender>
            }
          >
            <div className="row g-0 gy-2 gy-md-3">
              {relatedSupportTasks.map((item) => {
                return (
                  <div
                    className="col-12"
                    key={item.UID}
                    style={{ cursor: "pointer" }}
                  >
                    <CareInterventionSupportContent
                      key={item.UID}
                      careInterventionSupportRow={item}
                      careInterventionSupportEntity={
                        careInterventionSupportEntity
                      }
                      onSave={refresh}
                      uiStyle={"CardStyle"}
                    />
                  </div>
                );
              })}
            </div>

            {createCareInterventionSupportDlgDiv()}
          </RAFCollapse>
        )
        }
        {false && (
          <RAFCollapse
            title={`${CareEsioEntity.CareInterventionSupport.CollectionName}`}
            headerRowClassName="sectionHeader-xsm with-height bg-light-blue-3 border"
            contentCardClassName="relatedListOuterDiv p-2 p-md-3"
            headerBadge={
              isNotEmptyArray(relatedSupportTasks) && (
                <div className="secondary-text">
                  {" "}
                  <span className="e-badge e-badge-grey-border e-badge-notification e-badge-circle e-badge-md ms-2 position-static">
                    {relatedSupportTasks.length}
                  </span>
                </div>
              )
            }
            IsCollapsed={true}
            contentRowClassName="row g-0 gy-3"
            removeContentCardPadding
          //customButton={subGoalAddBtn()}
          >
            {relatedSupportTasks.map((item) => {
              return (
                <div className="col-12" key={item.UID}>
                  <CareInterventionSupportContent
                    key={item.UID}
                    careInterventionSupportRow={item}
                    careInterventionSupportEntity={
                      careInterventionSupportEntity
                    }
                    onSave={refresh}
                    uiStyle={"CardStyle"}
                    isRelatedSection
                  />
                </div>
              );
            })}
          </RAFCollapse>
        )}
      </div>
    );
  };
  const onMoreMenuClicked = (args: any) => {
    if (isNotNullAndUndefined(args) && isNotNullAndUndefined(args.item)) {
      switch (args.item.id) {
        case "Edit":
          showUpdateUpdateCareGoalDialog();
          break;
        case "Add":
          onClickAddSubGoal();
          break;
        default:
          break;
      }
    }
  };

  const getSubjectTemplate = () => {
    return (
      <div className="row g-0 gy-2 align-items-center justify-content-between">
        <div className="col-12">
          <div className="subtitle_1 semi_bold">
            {careGoalRow.Title ?? "Not set"}
          </div>
        </div>
        <div className="col-12 mt-2">

          <RAFDetailsValueWithSeparator
            outerClassName="h-auto"
            rowClassName="flex-nowrap g-2"
          >
            <RAFDetailFieldCustom
              title="Start Date:"
              value={careGoalRow.StartDate}
              labelDivClassName="col-auto"
              valueDivClassName="col-auto"
              labelClassName="body_2_light medium"
              valueClassName="subtitle_2"
              rowClassName="gx-2"
              fieldFormat={{ type: RAFDataType.Date, format: MomentFormats.DATE }}
            ></RAFDetailFieldCustom>
            <RAFDetailFieldCustom
              title="End Date:"
              value={careGoalRow.EndDate}
              labelDivClassName="col-auto"
              valueDivClassName="col-auto"
              labelClassName="body_2_light medium"
              valueClassName="subtitle_2"
              rowClassName="gx-2"
              fieldFormat={{ type: RAFDataType.Date, format: MomentFormats.DATE }}
            ></RAFDetailFieldCustom>
          </RAFDetailsValueWithSeparator>
        </div>
        {props.isSubGoal !== true &&
          isNotEmptyArray(props.subGoalItems) && (
            <div className="col-12">{getSubGoalItems()}</div>
          )}
        {isNotEmptyArray(state.relatedSupportTasks) && (
          <div className="col-12">
            <div className="custom__card__separator custom__card__separator__light"></div>
            {getRelatedSupportTasks()}
          </div>
        )}
      </div>
    )
  }

  const getFooterTemplate = () => {
    return (
      <>
        <div className="custom__card__separator custom__card__separator__light"></div>
        <div className="p-2 p-md-3 py-md-2">
          <div className="row gx-2 justify-content-between">
            <div className="col-auto">
              <RAFDetailsValueWithSeparator>
                {(isNotNullAndUndefined(careGoalRow.GoalType) ||
                  isNotNullAndUndefined(careGoalRow.GoalStatus) ||
                  isNotNullAndUndefined(careGoalRow.SubCategory)) && (
                    <div className="row gx-2">
                      {isNotNullAndUndefined(careGoalRow.GoalType) && (
                        <div className="col-auto">
                          <RAFDetailFieldCustom
                            value={careGoalRow.GoalType}
                            field="GoalType"
                            moduleName={CareEsioEntity.CareGoal.EntityName}
                            isColorOption={true}
                            showLabel={false}
                            mode="outlineView"
                            valueClassName="raf_badge"
                          ></RAFDetailFieldCustom>
                        </div>
                      )}
                      {isNotNullAndUndefined(careGoalRow.GoalStatus) && (
                        <div className="col-auto">
                          <RAFDetailFieldCustom
                            value={careGoalRow.GoalStatus}
                            field="GoalStatus"
                            moduleName={CareEsioEntity.CareGoal.EntityName}
                            isColorOption={true}
                            showLabel={false}
                            mode="outlineView"
                            valueClassName="raf_badge"
                          ></RAFDetailFieldCustom>
                        </div>
                      )}
                      {isNotNullAndUndefined(careGoalRow.SubCategory) && (
                        <div className="col-auto">
                          <span className="raf_badge raf_badge_tertiary">
                            {careGoalRow.SubCategory}
                          </span>
                        </div>
                      )}
                    </div>
                  )
                }
                <div
                  onClick={(e) => e.stopPropagation()}
                >
                  <RAFRecordInfo
                    createdBy={careGoalRow.CreatedBy}
                    createdDate={careGoalRow.CreatedDate}
                    modifiedDate={careGoalRow.ModifiedDate}
                    modifiedBy={careGoalRow.ModifiedBy}
                    lastActivityDate={careGoalRow.LastActivityDate}
                    spanContent="Updated "
                    dropdownBtnClassName="custom-button-sm"
                    spanContentClassName="body_3_light medium"
                    hideClockIcon
                  />
                </div>
              </RAFDetailsValueWithSeparator>
            </div>
            {hasPermissionToEdit ?
              <div className="col-auto">
                <RAFButtonComponent
                  action={RAFButtonConstant.Edit}
                  iconBtn
                  className="btn_state_warning white e-round"
                  onClick={showUpdateUpdateCareGoalDialog}
                ></RAFButtonComponent>
              </div>
              :
              ''
            }
          </div>
        </div>
      </>
    )
  }

  if (isNotNullAndUndefined(careGoalRow)) {
    return (
      <div className="col-12" key={careGoalRow.UID}>
        <CustomCardWidget
          footerTemplate={getFooterTemplate()}
          cardClassName="surface_neutral_base pointer"
          onClick={showUpdateUpdateCareGoalDialog}

        >
          {getSubjectTemplate()}
        </CustomCardWidget>
        {state.showUpdateCareGoal && (
          <DialogComponent
            header={`Update ${CareEsioEntity.CareGoal.DisplayName}`}
            showCloseIcon
            visible={state.showUpdateCareGoal}
            cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
            id={`dlg_update_${careGoalModule}`}
            content={updateUpdateCareGoalContent.bind(this)}
            isModal
            target="body"
            closeOnEscape={false}
            close={closeUpdateCareGoalDialog.bind(this)}
            open={PreventFocusOnDialogOpen}
            zIndex={1200}
            ref={careGoalContentDialogRef}
          ></DialogComponent>
        )}
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default React.memo(CareGoalContent);
