import * as R from "ramda";
import DOMPurify from "dompurify";
import {
  PropsWithChildren,
  Reducer,
  useEffect,
  useReducer,
  useRef,
} from "react";
import { FormRenderProps } from "react-final-form";
import RAFChoiceOption from "../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFDatePickerCustom from "../../../RAFComponents/Inputs/RAFDatePickerCustom";
import RAFDropdownCC from "../../../RAFComponents/Inputs/RAFDropdownCC";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import RAFHtmlEditor from "../../../RAFComponents/Inputs/RAFHtmlEditor";
import RAFTextArea from "../../../RAFComponents/Inputs/RAFTextArea";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFAttributeRelatedListProvider, {
  RAFAttributesContext,
} from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { showSuccessToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  RetrieveRecord,
  hideProgress,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import {
  IDialogProps,
  IsNullOrWhiteSpace,
  RAFFormComponentProps,
  getSaveRequest,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import { QueryAttributeJM } from "../../../RAFComponents/models/Common/QueryAttributeJM";
import { EntityRow } from "../../../RAFMaster/RMModules/Entity/EntityRow";
import { EvaluateScore } from "../../../RAFMaster/helpers/RMutils";
import { CareAssessmentPermissionConstants } from "../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  RAFButtonConstant,
  RAFLayout,
} from "../../../constants/Common/Constants";
import RAFEntityProvider, {
  RAFEntityContext,
} from "../../Common/Providers/RAFEntityProvider";
import { ReviseAssessment, SaveCareAssessment } from "./CareAssessmentHelper";
import { ITheme, Model } from "survey-core";
import "survey-core/defaultV2.min.css";
import { Survey } from "survey-react-ui";
import { customTheme } from "../../../RAFMaster/RMModules/FormLibrary/components/custom-theme";

interface IProps {
  moduleName?: string;
  careAssessmentRow?: any;
  onSave?: (entityId?: string, objectName?: string) => void;
  onClose?: () => void;
  objectUID?: string;
  isActive?: boolean;
  isEditable?: boolean;
}

interface IState {
  isActive?: boolean;
  isLoading?: boolean;
  careAssessmentRow?: any;
  selectedItemIndex?: number;
}

function ReviseCareAssessment({
  objectUID,
  isActive,
  isEditable,
  ...props
}: PropsWithChildren<IProps & RAFFormComponentProps & IDialogProps>) {
  let rafForm: FormRenderProps | null;
  let submitButtonRef = useRef(null);

  // let objectGUID = useRecoilValue(atomEditingRecordId(moduleName));
  // objectUID = isNotNullAndUndefined(objectGUID) ? objectGUID : objectUID;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isActive: isNotNullAndUndefined(isActive) ? isActive : false,
      careAssessmentRow: null,
      isLoading: true,
      selectedItemIndex: 0,
    }
  );

  useEffect(() => {
    let progressDiv = showProgress(
      `#dlg_update_${CareEsioEntity.CareAssessment.EntityName}`
    );
    let initialValues = {};
    RetrieveRecord(objectUID, CareEsioEntity.CareAssessment.EntityName).then(
      (careAssessmentRow) => {
        if (isNotNullAndUndefined(careAssessmentRow)) {
          initialValues["assessment_trigger"] = null;
          initialValues["reason"] = null;
          initialValues["care_recipientuid"] =
            careAssessmentRow.care_recipientuid;
          initialValues["care_recipient"] = careAssessmentRow.care_recipient;
          initialValues["care_plan"] = careAssessmentRow.title;
          initialValues["care_planuid"] = careAssessmentRow.UID;
          initialValues["current_date"] = new Date();
          initialValues["status"] = careAssessmentRow.status;
          initialValues["assessment_type"] = "Initial";
          initialValues["assessment_notes"] =
            careAssessmentRow.assessment_notes;
          initialValues["review_date"] = careAssessmentRow.review_date;
          initialValues["independence_level"] =
            careAssessmentRow.independence_level;
          initialValues["assessment"] = careAssessmentRow.assessment;
        }
        hideProgress(progressDiv);
        setState({
          isActive: true,
          isLoading: false,
          careAssessmentRow: initialValues,
        });
      }
    );
  }, [objectUID]);

  let { careAssessmentRow } = state;
  let moduleName =
    isNotNullAndUndefined(careAssessmentRow) &&
    isNotNullAndUndefined(careAssessmentRow.assesment)
      ? careAssessmentRow.assessment
      : CareEsioEntity.CareAssessment.EntityName;

  const reviseAssessment = (careAssessment?: any, url?: string) => {
    // let careAssessment =
    //   isNotNullAndUndefined(rafForm) && isNotNullAndUndefined(rafForm.values)
    //     ? rafForm.values
    //     : null;

    let updatedValues = R.clone(careAssessment);

    let progressDiv = showProgress(`#raf_dlg_Outer_Div_${moduleName}`);

    ReviseAssessment(objectUID, moduleName).then((reviseObjectId) => {
      setTimeout(() => {
        updatedValues.UID = reviseObjectId;
        let saveRequestData = getSaveRequest(updatedValues, reviseObjectId);
        let assessmentNotes = updatedValues["AssessmentNotes1"];
        if (isNotNullAndUndefined(assessmentNotes)) {
          updatedValues["assessment_notes"] = assessmentNotes;
          delete updatedValues["AssessmentNotes1"];
        }
        delete updatedValues["DueDate"];
        saveRequestData.EntityName = moduleName;

        SaveCareAssessment(saveRequestData, url).then((objectId) => {
          hideProgress(progressDiv);
          if (isNotNullAndUndefined(props.onSave)) {
            props.onSave();
          }
        });
      }, 500);
    });
  };

  const onSubmitObject = (
    values?: any,
    entity?: EntityRow,
    queryAttributes?: QueryAttributeJM[],
    formModel?: any,
    hasCustomForm?: boolean
  ) => {
    let updatedValues = R.clone(values);
    if (
      isNotNullAndUndefined(entity) &&
      isNotNullAndUndefined(entity.EntitySettings) &&
      isNotNullAndUndefined(entity.EntitySettings.EnableScore) &&
      entity.EntitySettings.EnableScore === true
    ) {
      updatedValues = EvaluateScore(values, queryAttributes);
    }

    if (hasCustomForm) {
      let isFormValid = formModel.validate(true, true);
      if (isFormValid) {
        let formData = formModel.data;
        updatedValues = { ...updatedValues, ...formData };
        reviseAssessment(updatedValues, "DataList/SaveForm");
      }
    } else {
      reviseAssessment(updatedValues);
      // let progressDiv = showProgress(`#raf_dlg_Outer_Div_${moduleName}`);
      // let saveRequestData = getSaveRequest(updatedValues, updatedValues.UID);
      // let assessmentNotes = updatedValues["AssessmentNotes1"];
      // if (isNotNullAndUndefined(assessmentNotes)) {
      //   updatedValues["AssessmentNotes"] = assessmentNotes;
      //   delete updatedValues["AssessmentNotes1"];
      // }
      // delete updatedValues["DueDate"];
      // saveRequestData.EntityName = isNotNullAndUndefined(updatedValues.Assessment)
      //   ? updatedValues.Assessment
      //   : CareEsioEntity.CareAssessment.EntityName;

      // SaveCareAssessment(saveRequestData).then((response) => {
      //   hideProgress(progressDiv);
      //   showSuccessToast("Saved successfully");
      //   if (props.onSave) props.onSave();
      // });
    }
  };

  const enableSubmitButton = (g: FormRenderProps | null) => {
    if (g) {
      if (
        isNotNullAndUndefined(submitButtonRef) &&
        isNotNullAndUndefined(submitButtonRef.current) &&
        isNotNullAndUndefined(submitButtonRef.current.toggleBtnPermission)
      ) {
        submitButtonRef.current.toggleBtnPermission(g.submitting);
      }
    }
  };

  const onClickBackBtn = () => {
    setState({
      selectedItemIndex: 0,
    });
  };

  const onClickNextBtn = () => {
    setState({
      selectedItemIndex: 1,
    });
  };

  if (
    isNotNullAndUndefined(objectUID) &&
    !IsNullOrWhiteSpace(objectUID) &&
    state.isActive
  ) {
    return (
      <div className="h-100">
        <div
          className="h-100"
          key={`${objectUID}_${moduleName}`}
          id={`raf_dlg_Outer_Div_${moduleName}`}
        >
          <RAFEntityProvider moduleName={moduleName}>
            <RAFEntityContext.Consumer>
              {({ entity, formLibrary }) => {
                let hasCustomForm = false;
                if (
                  isNotNullAndUndefined(formLibrary) &&
                  isNotNullAndUndefined(formLibrary.FormStructure)
                ) {
                  hasCustomForm = true;
                }
                let formModel = new Model(formLibrary?.FormStructure);

                formModel.applyTheme(customTheme as ITheme);
                let nextButton =
                  formModel.navigationBar.getActionById("sv-nav-next");
                let prevButton =
                  formModel.navigationBar.getActionById("sv-nav-prev");
                let completeButton =
                  formModel.navigationBar.getActionById("sv-nav-complete");
                nextButton.visible = false;
                prevButton.visible = false;
                completeButton.visible = false;

                formModel.onErrorCustomText.add(function (sender, options) {
                  if (options.name == "required") {
                    //options.text = options.obj.title + " is required";
                    options.text = "This field is required";
                  }
                });

                formModel.data = careAssessmentRow;
                return (
                  <RAFAttributeRelatedListProvider moduleName={moduleName}>
                    <RAFAttributesContext.Consumer>
                      {({ queryAttributes }) => {
                        let supportLevelField =
                          isNotNullAndUndefined(queryAttributes) &&
                          queryAttributes.length > 0
                            ? queryAttributes.find(
                                (x) => x.PropertyName === "independence_level"
                              )
                            : null;
                        let valueJson = isNotNullAndUndefined(supportLevelField)
                          ? supportLevelField.ValueJson
                          : null;
                        return (
                          <RAFForm
                            initialValues={careAssessmentRow}
                            formRef={(g) => {
                              enableSubmitButton(g);
                              return (rafForm = g);
                            }}
                            layout={RAFLayout.TwoColumnLayout}
                            onSubmit={(values) =>
                              onSubmitObject(
                                values,
                                entity,
                                queryAttributes,
                                formModel,
                                hasCustomForm
                              )
                            }
                            //primaryKey={objectUID}
                            //decorators={[accountDecorators]}
                            //convertBeforeSave={ConvertAccountRowToAccountDto}
                            className="h-100"
                          >
                            <div className="e-dlg-content-outer">
                              <div className="e-dlg-body-content">
                                {state.selectedItemIndex === 0 && (
                                  <>
                                    <div className="row g-3">
                                      <div className="col">
                                        <RAFDropdownCC
                                          field="assessment_trigger"
                                          label="Describe the event or circumstance that triggered this assessment"
                                          // description="Describe the event or circumstance that triggered this assessment"
                                          // descriptionAsLabel={
                                          //   true
                                          // }
                                          uitype="colorpicker"
                                          allowAdd={false}
                                          moduleName={
                                            CareEsioEntity.CareAssessment
                                              .EntityName
                                          }
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="row g-3">
                                      <div className="col">
                                        <RAFTextArea
                                          field="reason"
                                          label="Comment"
                                          placeholder="Comment"
                                          required
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                                {state.selectedItemIndex === 1 && (
                                  <>
                                    {hasCustomForm ? (
                                      <Survey model={formModel} />
                                    ) : (
                                      <div>
                                        <div className="row g-3">
                                          <div className="col">
                                            {isNotNullAndUndefined(
                                              careAssessmentRow
                                            ) &&
                                            isNotNullAndUndefined(
                                              careAssessmentRow.assessment_notes
                                            ) ? (
                                              <div>
                                                <div>
                                                  <label className="form-label with-height mb-1">
                                                    Observations from previous
                                                    assessment
                                                  </label>
                                                </div>
                                                <div className="secondary-header-text-dark px-3 py-2 bg-grey mb-3">
                                                  <span
                                                    className="description-text"
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        careAssessmentRow.assessment_notes !==
                                                        null
                                                          ? DOMPurify.sanitize(
                                                              careAssessmentRow.assessment_notes
                                                            )
                                                          : undefined,
                                                    }}
                                                  ></span>
                                                  {/* {careAssessmentRow.AssessmentNotes} */}
                                                </div>

                                                <RAFTextArea
                                                  field="AssessmentNotes1"
                                                  label={
                                                    "Observations from current assessment"
                                                  }
                                                  showLabel
                                                  description={
                                                    "Capture detailed notes and observations from the assessment"
                                                  }
                                                  descriptionAsLabel={true}
                                                  rows={3}
                                                />
                                              </div>
                                            ) : (
                                              <>
                                                <RAFTextArea
                                                  field="AssessmentNotes1"
                                                  label={
                                                    "Observations from current assessment"
                                                  }
                                                  //showLabel={false}
                                                  description={
                                                    isNotNullAndUndefined(
                                                      careAssessmentRow
                                                    ) &&
                                                    isNotNullAndUndefined(
                                                      careAssessmentRow.assessment_notes
                                                    )
                                                      ? careAssessmentRow.assessment_notes
                                                      : "Capture detailed notes and observations from the assessment"
                                                  }
                                                  descriptionAsLabel={true}
                                                  rows={3}
                                                />
                                              </>
                                            )}
                                          </div>
                                        </div>

                                        <div className="row g-3">
                                          <div className="col">
                                            <RAFDropdownCC
                                              field={"independence_level"}
                                              label={"Support Level"}
                                              showLabel
                                              //formGroupClassName="mb-0"
                                              uitype="smileyToggle"
                                              displayDropdownType="colorDropdown"
                                            >
                                              {valueJson &&
                                                valueJson.map((opt, index) => {
                                                  return (
                                                    <RAFChoiceOption
                                                      key={opt.Id}
                                                      label={opt.DisplayName}
                                                      value={opt.Name}
                                                      IconCss={opt.IconCss}
                                                      Icon={opt.Icon}
                                                    />
                                                  );
                                                })}
                                            </RAFDropdownCC>
                                          </div>
                                        </div>
                                        <div className="row g-3">
                                          <div className="col">
                                            <RAFDatePickerCustom
                                              field={`review_date`}
                                              description="Specify the date for reviewing the progress and effectiveness of the interventions or action plan"
                                              descriptionAsLabel={true}
                                              label="Review Date"
                                              secondaryField={`DueDate`}
                                              displayStyle="splitButton"
                                              showLabel
                                              dropdownDateOptions={[
                                                {
                                                  id: 1,
                                                  text: "15D",
                                                  value: "15",
                                                },
                                                {
                                                  id: 2,
                                                  text: "1M",
                                                  value: "30",
                                                },
                                                {
                                                  id: 3,
                                                  text: "2M",
                                                  value: "60",
                                                },
                                                {
                                                  id: 4,
                                                  text: "3M",
                                                  value: "90",
                                                },
                                                {
                                                  id: 5,
                                                  text: "6M",
                                                  value: "180",
                                                },
                                                {
                                                  id: 6,
                                                  text: "1Y",
                                                  value: "365",
                                                },
                                              ]}
                                              allowMaxValue={false}
                                              hideMinAndMaxValue
                                              showClearTextBtn
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>

                              {state.selectedItemIndex === 1 && (
                                <div className="e-dlg-footerContent">
                                  <div className="w-100">
                                    <div className="row gx-2">
                                      <div className="col-auto">
                                        <RAFButtonComponent
                                          action={RAFButtonConstant.Previous}
                                          onClick={onClickBackBtn}
                                          idString="PreviousContent"
                                          className="me-2"
                                        />
                                      </div>

                                      <RAFPermissionRender
                                        permissionName={
                                          CareAssessmentPermissionConstants.CareAssessmentUpdate
                                        }
                                      >
                                        <div className="col-auto ms-auto">
                                          <RAFButtonComponent
                                            type="submit"
                                            isPrimary
                                            action={RAFButtonConstant.Revise}
                                            //onClick={() => reviseAssessment()}
                                            idString="ReviseContent"
                                            disabled={
                                              rafForm && rafForm.submitting
                                            }
                                          />

                                          <RAFButtonComponent
                                            action={RAFButtonConstant.Cancel}
                                            onClick={props.onClose}
                                            idString="ReviseContent"
                                            className="ms-2"
                                          />
                                        </div>
                                      </RAFPermissionRender>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {state.selectedItemIndex === 0 && (
                                <div className="e-dlg-footerContent justify-content-end">
                                  <div className="w-100">
                                    <div className="row gx-2">
                                      <div className="col">
                                        <RAFButtonComponent
                                          type="button"
                                          isPrimary
                                          action={RAFButtonConstant.Next}
                                          disabled={
                                            rafForm && rafForm.submitting
                                          }
                                          className="new_style w-100"
                                          showIcon={false}
                                          onClick={onClickNextBtn}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </RAFForm>
                        );
                      }}
                    </RAFAttributesContext.Consumer>
                  </RAFAttributeRelatedListProvider>
                );
              }}
            </RAFEntityContext.Consumer>
          </RAFEntityProvider>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="container-fluid px-0"
        style={{ background: "transparent" }}
      ></div>
    );
  }
}
export default ReviseCareAssessment;
